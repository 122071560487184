import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import DataParser from "../../../../utlits/Dataparser";
import Sidebar from "../../../Layouts/Sidebar";
import CategoryService from "../../../../Services/CategoryService";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";

const CategoryLeadType = () => {
  const navigate = useNavigate();
  const [categoryPackages, setCategoryPackages] = useState([]);
  const companyID = DataParser.CompanyId();
  const [categoryPackageName, setCategoryPackageName] = useState("");
  const [categoryPackageEditName, setCategoryPackageEditName] = useState("");
  const [editId, setEditId] = useState("");

  const params = useParams();
  const id = params.id;

  const getData = () => {
    CategoryService.getAllLeadCategoryPackage(id, companyID).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
      const data = res.data.category_packages;
      setCategoryPackages(data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handelDelete = (e, id) => {
    e.preventDefault();
    const companyID = DataParser.CompanyId();
    const newData = categoryPackages.filter((el) => el.id !== id);
    setCategoryPackages(newData);
    CategoryService.deleteLeadCategoryPackage(id, companyID).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
    });
  };

  const handelSubmit = (e) => {
    e.preventDefault();
    const companyId = DataParser.CompanyId();
    const data = { company_id: companyId, name: categoryPackageName };
    CategoryService.createLeadCategoryPackage(id, data).then((res) => {
      if (res.Good) {
        var link = document.getElementById("categoryLeadTypeModalCreate");
        link.click();
        setCategoryPackageName("");
        getData();
      }
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.clear();
        navigate("/");
      }
    });
  };
  const handleOpenUpadte = (e, id) => {
    setEditId(id);
    CategoryService.viewLeadCategoryPackage(id).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
      const data = res.data.package[0].lead_package;
      setCategoryPackageEditName(data);
    });
  };

  const handelEditSubmit = (e) => {
    e.preventDefault();
    const companyId = DataParser.CompanyId();
    const data = { company_id: companyId, name: categoryPackageEditName };
    CategoryService.updateLeadCategoryPackage(editId, data).then((res) => {
      if (res.Good) {
        var link = document.getElementById("categoryModalEditType");
        link.click();
        getData();
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>Categories - Planit</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-cats-center justify-content-between">
                  <h4 className="mb-0">All Categories Packages</h4>
                  <div className="page-title-right">
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light"
                      data-bs-toggle="modal"
                      data-bs-target="#createCategoryLeadTypeModal"
                    >
                      Create Category Package
                    </button>

                    {/* Create modal content */}
                    <div
                      id="createCategoryLeadTypeModal"
                      className="modal fade"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="myModalLabel"
                      aria-hidden="true"
                    >
                      <form onSubmit={(e) => handelSubmit(e)}>
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="myModalLabel">
                                Create Category Package
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                id="categoryLeadTypeModalCreate"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              />
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-12 mb-3 d-flex" style={{ alignItems: "center" }}>
                                  <div className="col-md-4">
                                    <label
                                      className="form-label"
                                      style={{ paddingRight: 20, paddingBottom: 0, marginBottom: 0 }}
                                      htmlFor="CategoryPackages"
                                    >
                                      Category Packages
                                    </label>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="CategoryPackages"
                                      placeholder="Category Packages"
                                      value={categoryPackageName}
                                      onChange={(e) =>
                                        setCategoryPackageName(e.target.value)
                                      }
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-light waves-effect"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                onClick={(e) => handelSubmit(e)}
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Save changes
                              </button>
                            </div>
                          </div>
                          {/* /.modal-content modal-dialog-centere */}
                        </div>
                      </form>
                      {/* /.modal-dialog */}
                    </div>
                    {/* /.modal */}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table mb-0 table-striped">
                        <thead>
                          <tr className="tableHeaderbg">
                            <th>Name</th>
                            <th>Creation Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {categoryPackages &&
                            categoryPackages.map((el) => (
                              <tr>
                                <td className="tableset">{el.lead_package}</td>
                                <td className="tableset">
                                  {moment(el.created_at).format("D/MM/YYYY")}
                                </td>
                                <td className="tableset">
                                  <Link
                                    to="#"
                                    onClick={(e) => handelDelete(e, el.id)}
                                    className="invoice-action-view mr-1"
                                  >
                                    <i className="uil-trash iconscolor" />
                                  </Link>
                                  <Link
                                    onClick={(e) => handleOpenUpadte(e, el.id)}
                                    className="invoice-action-edit cursor-pointer"
                                  >
                                    <i
                                      className="uil-pen iconscolor"
                                      data-bs-toggle="modal"
                                      data-bs-target="#editCategoryModal"
                                    />
                                  </Link>
                                </td>
                              </tr>
                            ))}

                          <div
                            id="editCategoryModal"
                            className="modal fade"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="myModalLabel"
                            aria-hidden="true"
                          >
                            <form onSubmit={(e) => handelEditSubmit(e)}>
                              <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title"
                                      id="myModalLabel"
                                    >
                                      Edit Category Package
                                    </h5>
                                    <button
                                      type="button"
                                      className="btn-close"
                                      id="categoryModalEditType"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    />
                                  </div>
                                  <div className="modal-body">
                                    <div className="row">
                                      <div className="col-12 mb-3 d-flex" style={{ alignItems: "center" }}>
                                        <div className="col-md-4">
                                          <label
                                            className="form-label"
                                            style={{ paddingRight: 20, paddingBottom: 0, marginBottom: 0 }}
                                            htmlFor="CategoryPackages"
                                          >
                                            Category Packages
                                          </label>
                                        </div>
                                        <div className="col-md-8">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="CategoryPackages"
                                            placeholder="Category Packages"
                                            required
                                            value={categoryPackageEditName}
                                            onChange={(e) =>
                                              setCategoryPackageEditName(
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-light waves-effect"
                                      data-bs-dismiss="modal"
                                    >
                                      Close
                                    </button>
                                    <button
                                      onClick={(e) => handelEditSubmit(e)}
                                      type="button"
                                      className="btn btn-primary waves-effect waves-light"
                                    >
                                      Save changes
                                    </button>
                                  </div>
                                </div>
                                {/* /.modal-content modal-dialog-centere */}
                              </div>
                            </form>
                            {/* /.modal-dialog */}
                          </div>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryLeadType;
