import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import JobStatusService from "../../../Services/JobStatusService";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";

const CreateJobStatus = () => {
  const [name, setName] = useState("");
  const company_id = DataParser.CompanyId();
  const navigate = useNavigate();

  const onSubmitHandler = (e) => {
    e.preventDefault();
    try {
      const NewData = {
        name: name,
        company_id: company_id,
      };
      JobStatusService.createJobStatus(NewData).then((res) => {
        if (res.data === "Un-Athunticated request") {
          secureLocalStorage.removeItem("adminInfo");
          secureLocalStorage.removeItem("admins");
          navigate("/");
        }
        if (res.Good === true) {
          navigate(`/${DataParser.CompanyIdentifierRole()}/jobemplaoye`);
        }
      });
    } catch (err) {}
  };

  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    {/* Nav tabs */}
                    <ul className="nav nav-tabs" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#navtabs-home"
                          role="tab"
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home" />
                          </span>
                          <span className="d-none d-sm-block">
                            <i className="uil-user" />
                            &nbsp;General
                          </span>
                        </a>
                      </li>
                    </ul>
                    {/* Tab panes */}
                    <div className="tab-content p-3 text-muted">
                      <div
                        className="tab-pane active"
                        id="navtabs-home"
                        role="tabpanel"
                      >
                        <form onSubmit={(e) => onSubmitHandler(e)}>
                          <div className="mb-3">
                            <div className="col-6">
                              <label className="form-label" htmlFor="Name">
                                Name
                              </label>
                              <input
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                                className="form-control"
                                id="Name"
                                placeholder="Title"
                                required
                              />
                            </div>
                            <div className="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                              <button
                                type="submit"
                                className="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                              >
                                Save changes
                              </button>
                              <button
                                type="reset"
                                className="btn btn-light"
                                onClick={() => navigate(-1)}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateJobStatus;
