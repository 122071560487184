import { DatePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HolidayService from "../../../Services/HolidayService";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";
import StatusBadge from "../../Reuseable/StatusBadge";

const Holidays = () => {
  const [holidaysList, setHolidaysList] = useState([]);
  const [status, setStatus] = useState(true);
  const [editId, setEditId] = useState(null);
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [type, setType] = useState("Full Day");
  const [repeats, setRepeats] = useState(false);

  const [editStatus, setEditStatus] = useState(null);
  const [editName, setEditName] = useState(null);
  const [editDate, setEditDate] = useState(null);
  const [editType, setEditType] = useState("");
  const [editRepeats, setEditRepeats] = useState(null);
  const compid = DataParser.CompanyId();
  const datee = DataParser.getDateinFormat();
  useEffect(() => {
    setDate(datee);
    getAllHolidays();
  }, []);

  const getAllHolidays = () => {
    HolidayService.getHolidays(compid).then((res) => {
      const Data = res.data.holidays;
      setHolidaysList(Data);
    });
  };

  const createHolidays = (e) => {
    e.preventDefault();
    const Body = {
      company_id: compid,
      name: name,
      date: moment(date).format("DD/MM/YYYY"),
      type: type,
    };

    const link = document.getElementById("createmodalclose");
    HolidayService.createHolidays(Body).then((res) => {
      link.click();
      setName("");
      setDate(datee);
      setType("");
      getAllHolidays();
    });
  };

  const onStatusChange = () => {
    setStatus(!status);
  };
  const onRepeathange = () => {
    setRepeats(!repeats);
  };

  const handelDelete = (e, item) => {
    e.preventDefault();
    const newAay = holidaysList.filter((el) => el.id !== item.id);
    setHolidaysList(newAay);
    HolidayService.deleteHoliday(item.id).then((res) => {
      getAllHolidays();
    });
  };

  const handelEdit = (e, item) => {
    setEditStatus(item.status);
    setEditName(item.name);
    setEditDate(item.date);
    setEditType(item.type);
    setEditRepeats(item.repeats);
    setEditId(item.id);
  };

  const onDateChange = (date) => {
    setDate(date);
  };

  const handelUpdate = (e) => {
    e.preventDefault();
    const Body = {
      name: editName,
      date: editDate,
      type: editType,
    };
    const link = document.getElementById("holidyacloseEdit");
    HolidayService.updateHolidays(editId, Body).then((res) => {
      link.click();
      setEditStatus("");
      setEditName("");
      setEditDate(datee);
      setEditType("");
      setEditRepeats("");
      setEditId(null);
      getAllHolidays();
    });
  };

  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Holidays</h4>
                  <div className="page-title-right">
                    <>
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                        data-bs-toggle="modal"
                        data-bs-target="#createHolidaysModal"
                      >
                        Create Holidays
                      </button>
                      {/* Create modal content */}
                      <div
                        id="createHolidaysModal"
                        className="modal fade"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="myModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="myModalLabel">
                                Create Holidays
                              </h5>
                              <button
                                type="button"
                                id="createmodalclose"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              />
                            </div>
                            <form onSubmit={(e) => createHolidays(e)}>
                              <div className="modal-body">
                                <div className="row">
                                  <div className="col-12 mb-3 d-flex">
                                    <div className="col-md-3">
                                      <label
                                        className="form-label"
                                        style={{ paddingRight: "20px" }}
                                        htmlFor="Name"
                                      >
                                        Name
                                      </label>
                                    </div>
                                    <div className="col-md-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="Name"
                                        placeholder="Title"
                                        required
                                        value={name}
                                        onChange={(e) =>
                                          setName(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 mb-3 d-flex">
                                    <div className="col-md-3">
                                      <label
                                        className="form-label"
                                        style={{ paddingRight: "20px" }}
                                        htmlFor="Name"
                                      >
                                        Date
                                      </label>
                                    </div>
                                    <div className="col-md-9">
                                      <DatePicker
                                        // disabledDate={(current) => {
                                        //   let customDate =
                                        //     moment().format("YYYY-MM-DD");
                                        //   return (
                                        //     current &&
                                        //     current <
                                        //       moment(customDate, "YYYY-MM-DD")
                                        //   );
                                        // }}
                                        allowClear={false}
                                        bordered={false}
                                        value={moment(date)}
                                        onChange={onDateChange}
                                        format={"DD-MM-YYYY"}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 mb-3 d-flex">
                                    <div className="col-md-3">
                                      <label
                                        className="form-label"
                                        style={{ paddingRight: "20px" }}
                                        htmlFor="Name"
                                      >
                                        Type
                                      </label>
                                    </div>
                                    <div className="col-md-9">
                                      <select
                                        className="form-select"
                                        onChange={(e) =>
                                          setType(e.target.value)
                                        }
                                        value={type}
                                      >
                                        <option value="">Select</option>
                                        <option value="Full Day">
                                          Full Day
                                        </option>
                                        <option value="Half Day">
                                          Half Day
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  {/* <div className="col-12 mb-3 d-flex">
                                    <div className="col-md-3">
                                      <label
                                        className="form-label"
                                        style={{ paddingRight: "20px" }}
                                        htmlFor="Name"
                                      >
                                        Repeat Anually
                                      </label>
                                    </div>
                                    <div className="col-md-9">
                                      <div
                                        className="form-check form-switch form-switch-md mb-3"
                                        dir="ltr"
                                      >
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="customSwitchsizemd"
                                          onChange={(e) => onRepeathange(e)}
                                          defaultChecked={repeats}
                                        />
                                      </div>
                                    </div>
                                  </div> */}
                                  <div className="col-12 mb-3 d-flex">
                                    <div className="col-md-3">
                                      <label
                                        className="form-label"
                                        style={{ paddingRight: "20px" }}
                                        htmlFor="Name"
                                      >
                                        Status
                                      </label>
                                    </div>
                                    <div className="col-md-9">
                                      <div
                                        className="form-check form-switch form-switch-md mb-3"
                                        dir="ltr"
                                      >
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="customSwitchsizemd"
                                          onChange={(e) => onStatusChange(e)}
                                          defaultChecked={status}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-light waves-effect"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary waves-effect waves-light"
                                >
                                  Save changes
                                </button>
                              </div>
                            </form>
                          </div>
                          {/* /.modal-content modal-dialog-centere */}
                        </div>
                        {/* /.modal-dialog */}
                      </div>
                      {/* /.modal */}
                    </>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table mb-0 table-striped">
                        <thead>
                          <tr className="tableHeaderbg">
                            <th>Sr#</th>
                            <th>Name</th>
                            <th>Date</th>
                            <th>Type</th>
                            {/* <th>Repeat Annually</th> */}
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {holidaysList &&
                            holidaysList.map((item, index) => (
                              <tr key={item.id}>
                                <th className="tableset"  scope="row">{index + 1}</th>
                                <th className="tableset">{item.name}</th>
                                <th className="tableset">
                                  {item.date}
                                </th>
                                <th className="tableset">{item.type}</th>
                                {/* <th>
                                  {item.repeats === true ? (
                                    <span className="btn btn-success waves-effect waves-light btn-sm">
                                      Yes
                                    </span>
                                  ) : (
                                    <span className="btn btn-danger waves-effect waves-light btn-sm">
                                      No
                                    </span>
                                  )}
                                </th> */}
                                <th className="tableset">
                                  <StatusBadge
                                    item={item}
                                    api={HolidayService.statusUpdate}
                                  />
                                </th>
                                <th className="tableset">
                                  <Link
                                    to="#"
                                    className="cursor-pointer iconscolor"
                                    onClick={(e) => handelDelete(e, item)}
                                  >
                                    <i className="uil-trash iconscolor" />
                                  </Link>

                                  <Link
                                    to="#"
                                    className="cursor-pointer iconscolor"
                                    onClick={(e) => handelEdit(e, item)}
                                  >
                                    <i
                                      className="uil-pen iconscolor"
                                      data-bs-toggle="modal"
                                      data-bs-target="#editHolidaysModal"
                                    />
                                  </Link>
                                </th>
                              </tr>
                            ))}
                          {/* edit modal content */}

                          <div
                            id="editHolidaysModal"
                            className="modal fade"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="myModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5 className="modal-title" id="myModalLabel">
                                    Edit Holidays
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    id="holidyacloseEdit"
                                    aria-label="Close"
                                  />
                                </div>
                                <form onSubmit={(e) => handelUpdate(e)}>
                                  <div className="modal-body">
                                    <div className="row">
                                      <div className="col-12 mb-3 d-flex">
                                        <div className="col-md-3">
                                          <label
                                            className="form-label"
                                            style={{ paddingRight: "20px" }}
                                            htmlFor="Name"
                                          >
                                            Name
                                          </label>
                                        </div>
                                        <div className="col-md-9">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="Name"
                                            placeholder="Title"
                                            required
                                            value={editName}
                                            onChange={(e) =>
                                              setEditName(e.target.value)
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-12 mb-3 d-flex">
                                        <div className="col-md-3">
                                          <label
                                            className="form-label"
                                            style={{ paddingRight: "20px" }}
                                            htmlFor="Name"
                                          >
                                            Date
                                          </label>
                                        </div>
                                        <div className="col-md-9">
                                          <DatePicker
                                            disabledDate={(current) => {
                                              let customDate =
                                                moment().format("YYYY-MM-DD");
                                              return (
                                                current &&
                                                current <
                                                  moment(
                                                    customDate,
                                                    "YYYY-MM-DD"
                                                  )
                                              );
                                            }}
                                            allowClear={false}
                                            bordered={false}
                                            value={moment(editDate)}
                                            onChange={onDateChange}
                                            format={"DD-MM-YYYY"}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-12 mb-3 d-flex">
                                        <div className="col-md-3">
                                          <label
                                            className="form-label"
                                            style={{ paddingRight: "20px" }}
                                            htmlFor="Name"
                                          >
                                            Type
                                          </label>
                                        </div>
                                        <div className="col-md-9">
                                          <select
                                            className="form-select"
                                            onChange={(e) =>
                                              setEditType(e.target.value)
                                            }
                                            value={editType}
                                          >
                                            <option value="">Select</option>
                                            <option value="full">
                                              Full Day
                                            </option>
                                            <option value="half">
                                              Half Day
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-12 mb-3 d-flex">
                                        <div className="col-md-3">
                                          <label
                                            className="form-label"
                                            style={{ paddingRight: "20px" }}
                                            htmlFor="Name"
                                          >
                                            Repeat Anually
                                          </label>
                                        </div>
                                        <div className="col-md-9">
                                          <div
                                            className="form-check form-switch form-switch-md mb-3"
                                            dir="ltr"
                                          >
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="customSwitchsizemd"
                                              onChange={(e) => onRepeathange(e)}
                                              defaultChecked={editRepeats}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-12 mb-3 d-flex">
                                        <div className="col-md-3">
                                          <label
                                            className="form-label"
                                            style={{ paddingRight: "20px" }}
                                            htmlFor="Name"
                                          >
                                            Status
                                          </label>
                                        </div>
                                        <div className="col-md-9">
                                          <div
                                            className="form-check form-switch form-switch-md mb-3"
                                            dir="ltr"
                                          >
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="customSwitchsizemd"
                                              onChange={(e) =>
                                                onStatusChange(e)
                                              }
                                              defaultChecked={editStatus}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-light waves-effect"
                                      data-bs-dismiss="modal"
                                    >
                                      Close
                                    </button>
                                    <button
                                      type="button"
                                      onClick={(e) => handelUpdate(e)}
                                      className="btn btn-primary waves-effect waves-light"
                                    >
                                      Save changes
                                    </button>
                                  </div>
                                </form>
                              </div>
                              {/* /.modal-content modal-dialog-centere */}
                            </div>
                            {/* /.modal-dialog */}
                          </div>
                          {/* /.modal */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Holidays;
