import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import Sidebar from "../../Layouts/Sidebar";
import AddLeaves from "./AddLeaves";
import secureLocalStorage from "react-secure-storage";
import LeaveEntitlementService from "../../../Services/LeaveEntitlementService";
import DataParser from "../../../utlits/Dataparser";
import LeaveApplicationService from "../../../Services/LeaveApplicationService";
import shortid from "shortid";
import LeaveBadge from "../../Reuseable/LeaveBadge";

const Leaves = () => {
  const location = useLocation();
  const adminInfo = JSON.parse(secureLocalStorage.getItem("adminInfo"));
  const role = adminInfo?.user?.userdata?.role_title;

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [DataForEmployee, setDataForEmployee] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  // Serach states
  const [userEntitlementList, setUserEntitlementsList] = useState([]);
  const [leavePolicyList, setLeavePolicyList] = useState([]);
  const [userLeavesList, setUserLeavesList] = useState([]);
  const [userLeaveCheck, setUserLeavesCheck] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [leaveStatus, setLeaveStatus] = useState("");
  const [leaveFrom, setLeaveFrom] = useState("");
  const [leaveTo, setLeaveTo] = useState("");
  const compid = DataParser.CompanyId();
  const userid = DataParser.UserId();

  const getUserEntitlements = (u, c) => {
    LeaveEntitlementService.userLeaveEntitlement(u, c).then((res) => {
      const Data = res.data.policy[0].leavePolicies;
      setLeavePolicyList(Data);
    });
  };

  useEffect(() => {
    if (location.state !== null) {
      const stateData = location?.state?.data;
      getLeaveApplicationsAll(stateData, compid);
      getUserEntitlements(stateData, compid);
      // userLeaves(stateData, compid);
    } else {
      getLeaveApplicationsAll(userid, compid);
      getUserEntitlements(userid, compid);
      // userLeaves(userid, compid);
    }
  }, []);

  useEffect(() => {
    if (pageSize > 10) {
      setCurrentPage(1);
    }
  }, [pageSize]);

  const getLeaveApplicationsAll = (u, c) => {
    LeaveApplicationService.getLeaveApplicationUser(u, c).then((res) => {
      const data = res.data.leave_applications;
      setDataForEmployee(data);
    });
  };

  // const userLeaves = (u, c) => {
  //   LeaveApplicationService.userLeave(u, c).then((res) => {
  //     const data = res.data.userLeaves;
  //     setUserLeavesList(data);
  //     var max = data.reduce(function (a, b) {
  //       return a > b ? a : b;
  //     });
  //     setUserLeavesCheck(max.date);
  //   });
  // };

  const checkLeaves = (e, item) => {
    e.preventDefault();
    LeaveApplicationService.userLeaveByApplication(item.id, compid).then(
      (res) => {
        const datas = res.data.userLeaves;
        setUserLeavesList(datas);
      }
    );
  };

  return (
    <>
      <Helmet>
        <title>Leaves - Planit</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <AddLeaves
              id={"offcanvasRightLeaves"}
              labelledby={"offcanvasRightLeavesLabel"}
              userLeaveCheck={userLeaveCheck}
              getLeaveApplicationsAll={getLeaveApplicationsAll}
              leavePolicyList={leavePolicyList}
              location={location}
            />
            <div className="row px-5">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Leaves</h4>
                  <div className="page-title-right">
                    <Link to="#">
                      <span
                        type="button"
                        className="btn btnsss waves-effect waves-light"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRightLeaves"
                        aria-controls="offcanvasRightLeaves"
                      >
                        Add Leaves
                      </span>
                    </Link>
                  </div>
                </div>
              </div>

              {/* this is for the users */}
              {/* {role !== "Company Admin" ? (
                <div className="row">
                  {userEntitlementList.map((e) => (
                    <div key={shortid.generate()} className="col-md-3">
                      <div className="stats-info card">
                        <h4>{e.leaveType}</h4>
                        <h4>
                          {e.used}/{e.total}
                        </h4>
                      </div>
                    </div>
                  ))}
                  <div className="col-md-3">
                    <div className="stats-info card">
                      <h4>Remaing</h4>
                      <h4>
                        {userEntitlementList.reduce((a, b) => a + b.total, 0) -
                          userEntitlementList.reduce((a, b) => a + b.used, 0)}
                      </h4>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="stats-info card">
                      <h4> Unpaid Leaves</h4>
                      <h4>
                        {userLeavesList.filter((s) => s.typ === 0).length}
                      </h4>
                    </div>
                  </div>
                </div>
              ) : null} */}
              {/* this is for the users end */}

              {role !== "Company Admin" ? (
                <div className="card">
                  <div className="card-body">
                    <table id="dataEable" className="table mb-0 table-striped">
                      <thead>
                        <tr className="tableHeaderbg">
                          <th>Leave Type</th>
                          <th>From</th>
                          <th>To</th>
                          <th>No of Days</th>
                          <th>Reason</th>
                          <th>Approved</th>
                          <th>Approved By</th>
                          <th>Action</th>
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {DataForEmployee.map((el, index) => (
                          <tr
                            key={shortid.generate()}
                            role="row"
                            className={index % 2 === 0 ? "odd" : "even"}
                          >
                            <td className="tableset">
                              {el.leav_type !== null ? el.leav_type.name : "-"}
                            </td>
                            <td className="tableset">
                              {moment(el.from_date).format("DD/MM/YYYY")}
                            </td>
                            <td className="tableset">
                              {moment(el.to_date).format("DD/MM/YYYY")}
                            </td>

                            <td className="tableset">{el.totalleaves}</td>
                            <td className="tableset">{el.reason}</td>
                            <td className="tableset">
                              {el.status === 1 ? (
                                <>
                                  <span className="btn btn-success waves-effect waves-light btn-sm">
                                    Approved
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="btn btn-danger waves-effect waves-light btn-sm">
                                    Not Approved
                                  </span>
                                </>
                              )}
                            </td>
                            <td className="tableset">{el.approvedBy.name}</td>
                            <td>
                              {DataParser.userRoleTitle() === "HR" ||
                              DataParser.userRoleTitle() === "Company Admin" ||
                              DataParser.userRoleTitle() === "Sub Admin" ? (
                                <Link
                                  to="#"
                                  onClick={(e) => checkLeaves(e, el)}
                                  data-bs-toggle="modal"
                                  data-bs-target="#createCategoryModal"
                                >
                                  <i className="uil-pen iconscolor" />
                                </Link>
                              ) : (
                                <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#createCategoryModal"
                                >
                                  <i className="uil-eye iconscolor" />
                                </Link>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/* Create modal content */}
                    <div
                      id="createCategoryModal"
                      className="modal fade"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="myModalLabel"
                      aria-hidden="true"
                    >
                      <form>
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="myModalLabel">
                                User Leave
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                id="categoryModalCreate"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              />
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div
                                  className="col-12 mb-3 d-flex"
                                  style={{ alignItems: "center" }}
                                >
                                  <table
                                    id="dataEable"
                                    className="table mb-0 table-striped"
                                  >
                                    <thead>
                                      <tr className="tableHeaderbg">
                                        <th style={{ width: "80%" }}>Date</th>
                                        <th>Paid Leave</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {userLeavesList.map((es) => (
                                        <tr key={shortid.generate()}>
                                          <td className="tableset">
                                            {es.date}
                                          </td>
                                          {DataParser.userRoleTitle() ===
                                            "HR" ||
                                          DataParser.userRoleTitle() ===
                                            "Company Admin" ||
                                          DataParser.userRoleTitle() ===
                                            "Sub Admin" ? (
                                            <>
                                              <td className="tableset">
                                                <LeaveBadge
                                                  item={es}
                                                  api={
                                                    LeaveApplicationService.userLeaveTypeUpdate
                                                  }
                                                />
                                              </td>
                                            </>
                                          ) : (
                                            <>
                                              <td className="tableset">
                                                {es.typ === 1 ? (
                                                  <span className="btn btn-success waves-effect waves-light btn-sm">
                                                    Yes
                                                  </span>
                                                ) : (
                                                  <span className="btn btn-danger waves-effect waves-light btn-sm">
                                                    No
                                                  </span>
                                                )}
                                              </td>
                                            </>
                                          )}
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-light waves-effect"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                          {/* /.modal-content modal-dialog-centere */}
                        </div>
                      </form>
                      {/* /.modal-dialog */}
                    </div>
                    {/* /.modal */}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Leaves;
