import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import Sidebar from "../../Layouts/Sidebar";
import Papa from "papaparse";
import DataParser from "../../../utlits/Dataparser";
import AttendanceService from "../../../Services/AttendanceService";
import UserProfile from "../../Reuseable/UserProfile.";
import CompanyService from "../../../Services/CompanyService";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import moment from "moment";

const AttendanceHr = () => {
  const navigate = useNavigate();
  const compId = DataParser.CompanyId();
  const userid = DataParser.UserId();
  const daysInMonth = DataParser.getDaysInMonth();
  const currentDate = DataParser.getDateinFormat();

  const [parsedData, setParsedData] = useState([]);

  const [tableRows, setTableRows] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [values, setValues] = useState([]);
  const [depList, setDepList] = useState([]);
  const [holidayList, setHolidayList] = useState([]);
  const [attendence, setAttendence] = useState([]);
  const [employeeid, setEmployeeid] = useState("");
  const [deptId, setDeptId] = useState("");
  const [selectMonth, setSelectMonth] = useState("");
  const [selectYear, setSelectYear] = useState("");

  useEffect(() => {
    let numberOfDays = ["Employee"];

    for (let i = 1; i <= daysInMonth; i++) {
      numberOfDays.push(i);
    }
    setTableRows(numberOfDays);

    getAttendence();
  }, []);

  const getAttendence = () => {
    let newArr = [];
    setLoading(true);
    AttendanceService.getAttendanceHrNew(compId).then((res) => {
      const data = res.data.attandence;
      setHolidayList(res.data.holidays);
      for (let i = 0; i < data.length; i++) {
        let dep = data[i].department[0];
        newArr.push(dep);
      }
      const unique = newArr.filter(
        (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
      );
      setDepList(unique);
      setAttendence(data);
      setLoading(false);
    });
  };

  const changeHandler = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];
        results.data
          .filter((els) => els.employee_Id !== "")
          .map((d) => {
            rowsArray.push(Object.keys(d));
            let obj = {
              empid: parseInt(Object.values(d)[1]),
              date: Object.values(d)[0],
              timeIn: Object.values(d)[2],
              timeOut: Object.values(d)[3],
            };
            valuesArray.push(obj);
          });
        setParsedData(results.data);

        setValues(valuesArray);

        const companyid = DataParser.CompanyId();
        const Data = {
          company_id: companyid,
          data: valuesArray,
        };
        AttendanceService.uploadAttendance(Data).then((res) => {
          getAttendence();
        });
      },
    });
  };

  const handelSearch = (e) => {
    e.preventDefault();
    setLoading(true);
    AttendanceService.getAttendancebySearchHrNew(
      compId,
      employeeid,
      deptId,
      selectMonth,
      selectYear
    ).then((res) => {
      const Data = res.data.attandence;
      setHolidayList(res.data.holidays);
      const daysInJanuary = DataParser.getDaysInMonthss(
        selectYear,
        selectMonth
      );
      //setting months
      let numberOfDays = ["Employee"];
      for (let i = 1; i <= daysInJanuary; i++) {
        numberOfDays.push(i);
      }
      setTableRows(numberOfDays);
      setAttendence(Data);
      // setSelectMonth("");
      // setSelectYear("");
      setEmployeeid("");
      setLoading(false);
    });
  };

  const handelCheckAttendance = (e, s) => {
    e.preventDefault();
    const name = s.name;
    navigate(`/${DataParser.CompanyIdentifierRole()}/employe-attendance`, {
      state: { data: s.id, name: name },
    });
  };

  // const AttendanceManage = (elsss) => {
  //   if(elsss){

  //   }
  // };

  const onChange = (date, dateString) => {
    console.log(dateString);
    let year = dateString.split("-")[0];
    let month = dateString.split("-")[1];
    setSelectMonth(month);
    setSelectYear(year);
  };

  //   function getAttendanceStatus(attendance, holidays, leaves) {
  //     return attendance.map(att => {
  //         let date = moment(att.date).format("DD/MM/YYYY");
  //         if (leaves.some(leave => leave.date === date)) {
  //             return {...att, status: "On Leave"};
  //         } else if (holidays.some(holiday => holiday.date === date)) {
  //             return {...att, status: "On Holiday"};
  //         } else if(att.timein === "Leave" || att.timein === "off" ){
  //             return {...att, status: "On Leave"};
  //         }
  //         else {
  //             return {...att, status: "Absent"};
  //         }
  //     });
  // }

  function getAttendanceStatus(attendance, holidays, leaves) {
    return attendance.map((att) => {
      let date = moment(att.date).format("DD/MM/YYYY");
      if (leaves.some((leave) => leave.date === date)) {
        return (
          <td className="tableset">
            <i class="fas fas-user-times text-info"></i>
          </td>
        );
      } else if (holidays.some((holiday) => holiday.date === date)) {
        return (
          <td className="tableset">
            <i class="fas fas-h-square text-danger"></i>
          </td>
        );
      } else if (att.timein === "off") {
        return (
          <td className="tableset">
            <i class="fas fas-ban text-danger"></i>
          </td>
        );
      } else if (att.timein === "Leave") {
        return (
          <td className="tableset">
            <i class="fas fas-user-times text-info"></i>
          </td>
        );
      } else if (att.markType && att.markType === 1) {
        return (
          <td className="tableset">
            <i class="fa fas-home text-info"></i>
          </td>
        );
      } else if (att.timein && att.timein !== "Leave" && att.timein !== "off") {
        return (
          <td className="tableset">
            <i class="fa fa-check text-success"></i>
          </td>
        );
      } else {
        return (
          <td className="tableset">
            <i class="fas fas-check text-danger"></i>
          </td>
        );
      }
    });
  }

  return (
    <>
      <Helmet>
        <title>Attendance - Planit</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Attendance</h4>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col">
                      <div className="form-group form-focus">
                        <select
                          required
                          value={employeeid}
                          onChange={(e) => setEmployeeid(e.target.value)}
                          className="form-select"
                        >
                          <option value="">Employee</option>
                          {attendence.map((user) => (
                            <option key={user.id} value={user.id}>
                              {user.first_name} {user.last_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group form-focus">
                        <select
                          required
                          value={deptId}
                          onChange={(e) => setDeptId(e.target.value)}
                          className="form-select"
                        >
                          <option value="">Depertment</option>
                          {depList.map((dep) => (
                            <option key={dep.id} value={dep.id}>
                              {dep.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col">
                      <DatePicker
                        className="form-select-custom"
                        onChange={onChange}
                        picker="month"
                      />
                    </div>
                    <div className="col">
                      <div className="d-grid">
                        <Link
                          style={{
                            backgroundColor: "#00b5b8 !important",
                            borderColor: "#00b5b8 !important",
                            color: "white !important",
                          }}
                          to="#"
                          onClick={(e) => handelSearch(e)}
                          className="btn btn-color"
                        >
                          <i class="uil-search"></i> Search
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="page-title-right d-flex justify-content-end">
                    <label htmlFor="file-input-notes">
                      <p htmlFor="file-input-notes">
                        <span
                          style={{
                            backgroundColor: "#00b5b8",
                            borderColor: "#00b5b8",
                            color: "white",
                          }}
                          type="button"
                          className="btn btn-color waves-effect waves-light"
                        >
                          <i class="uil-cloud-upload"></i> Upload Attendance
                        </span>
                      </p>
                    </label>
                    <input
                      type="file"
                      name="file"
                      id="file-input-notes"
                      accept=".csv"
                      onChange={(e) => {
                        changeHandler(e);
                      }}
                      style={{ display: "none" }}
                    />
                    <a
                      style={{ marginLeft: "10px" }}
                      href="/assets/sampleData.csv"
                      download="Attandance Upload Sample"
                      target="_blank"
                    >
                      <button className="btn btn-color waves-effect waves-light">
                        <i class="uil-cloud-download"></i> Download Sample
                      </button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table mb-0 table-striped">
                        <thead>
                          <tr className="tableHeaderbg">
                            {tableRows.map((els) => (
                              <th key={els}>{els}</th>
                            ))}
                          </tr>
                        </thead>

                        <tbody>
                          {attendence.map((els) => (
                            <tr key={els.id}>
                              <td
                                className="tableset"
                                onClick={(e) => handelCheckAttendance(e, els)}
                                style={{ cursor: "pointer" }}
                              >
                                <UserProfile
                                  username={`${els?.first_name} ${els?.last_name}`}
                                  userImage={els?.image}
                                />
                                &nbsp; &nbsp;
                                {`${els?.first_name} ${els?.last_name}`}
                              </td>
                              {els &&
                                getAttendanceStatus(
                                  els.attandence,
                                  holidayList,
                                  els.leaves
                                )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttendanceHr;
