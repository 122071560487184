import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";

const Hire = () => {
  return (
    <>
      <Helmet>
        <title>Hiring - Planit</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Hiring</h4>
                  <div className="page-title-right">
                    <Link
                      to={`/${DataParser.CompanyIdentifierRole()}/hire/create`}
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Create vacancy
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-12 icon_div">
                <div className="row">
                  <div className="col-lg-2 col-md-3 col-sm-6 text-center">
                    <fieldset
                      className="border"
                      style={{ padding: 8, borderRadius: 20 }}
                    >
                      <legend
                        className="font_11"
                        style={{ background: "#7571F9" }}
                      >
                        1
                      </legend>
                      <Link to="#" className="app_btn open_pending_app">
                        <img
                          className="recruit_img"
                          src="/assets/images/applicants.png"
                          alt="Employee Profile"
                        />
                      </Link>
                    </fieldset>
                    <span id="appli" className="font_12 font-weight-bold">
                      Applicants
                    </span>
                  </div>
                  <div className="col-lg-2 col-md-3 col-sm-6 text-center">
                    <fieldset
                      className="border"
                      style={{ padding: 8, borderRadius: 20 }}
                    >
                      <legend
                        className="font_11"
                        style={{ background: "#F8C038" }}
                      >
                        0
                      </legend>
                      <Link to="#" className="app_btn open_shortlisted_app">
                        <img
                          className="recruit_img"
                          src="/assets/images/shortlisted.png"
                          alt="Employee Profile"
                        />
                      </Link>
                    </fieldset>
                    <span className="font_12 font-weight-bold">
                      Shortlisted
                    </span>
                  </div>
                  <div className="col-lg-2 col-md-3 col-sm-6 text-center">
                    <fieldset
                      className="border"
                      style={{ padding: 8, borderRadius: 20 }}
                    >
                      <legend
                        className="font_11"
                        style={{ background: "#2ABFCC" }}
                      >
                        1
                      </legend>
                      <Link to="#" className="app_btn open_interviewed_app">
                        <img
                          className="recruit_img"
                          src="/assets/images/interviewed.png"
                          alt="Employee Profile"
                        />
                      </Link>
                    </fieldset>
                    <span className="font_12 font-weight-bold">
                      Interviewed
                    </span>
                  </div>
                  <div className="col-lg-2 col-md-3 col-sm-6 text-center">
                    <fieldset
                      className="border"
                      style={{ padding: 8, borderRadius: 20 }}
                    >
                      <legend
                        className="font_11"
                        style={{ background: "#0ACF97" }}
                      >
                        0{" "}
                      </legend>
                      <Link to="#" className="app_btn open_accepted_app">
                        <img
                          className="recruit_img"
                          src="/assets/images/accepted.png"
                          alt="Employee Profile"
                        />
                      </Link>
                    </fieldset>
                    <span className="font_12 font-weight-bold">Accepted</span>
                  </div>
                  <div className="col-lg-2 col-md-3 col-sm-6 text-center">
                    <fieldset
                      className="border"
                      style={{ padding: 8, borderRadius: 20 }}
                    >
                      <legend
                        className="font_11"
                        style={{ background: "#FF5E5E" }}
                      >
                        0{" "}
                      </legend>
                      <Link to="#" className="app_btn open_rejected_app">
                        <img
                          className="recruit_img"
                          src="/assets/images/rejected.png"
                          alt="Employee Profile"
                        />
                      </Link>
                    </fieldset>
                    <span className="font_12 font-weight-bold">Rejected</span>
                  </div>
                  <div className="col-lg-2 col-md-3 col-sm-6 text-center">
                    <fieldset
                      className="border"
                      style={{ padding: 8, borderRadius: 20 }}
                    >
                      <legend
                        className="font_11"
                        style={{ background: "#97CEF8" }}
                      >
                        0{" "}
                      </legend>
                      <Link to="#">
                        <img
                          className="recruit_img"
                          src="/assets/images/talent-pool.png"
                          alt="Employee Profile"
                        />
                      </Link>
                    </fieldset>
                    <span className="font_12 font-weight-bold">
                      Talent Pool
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    {/* Nav tabs */}
                    <ul
                      className="nav nav-tabs nav-tabs-custom nav-justified"
                      role="tablist"
                      style={{width: '70vh'}}
                    >
                      <li className="nav-item custome text-left">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#brief"
                          role="tab"
                          aria-selected="true"
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home" />
                          </span>
                          <span className="d-none d-sm-block">
                          Vacancies List
                          </span>
                        </a>
                      </li>
                      <li className="nav-item text-left">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#leavepolicy"
                          role="tab"
                          aria-selected="false"
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-user" />
                          </span>
                          <span className="d-none d-sm-block">
                          Talent Pool
                          </span>
                        </a>
                      </li>
                    </ul>
                    {/* Tab panes */}
                    <div className="tab-content pt-3 text-muted">
                      <div
                        className="tab-pane active"
                        id="brief"
                        role="tabpanel"
                      >
                           <div className="col-lg-12">
                <div className="card">
                  <div className="card-body p-0">
                    <table className="table mb-0 table-striped">
                      <thead>
                        <tr className="tableHeaderbg">
                          <th>Title</th>
                          <th>Age Limit</th>
                          <th>Total Applicant</th>
                          <th>Expiry</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td className="tableset">1</td>
                          <td className="tableset">1</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
                      </div>
                      <div
                        className="tab-pane"
                        id="leavepolicy"
                        role="tabpanel"
                      >
                           <div className="col-lg-12">
                <div className="card">
                  <div className="card-body p-0">
                    <table className="table mb-0 table-striped">
                      <thead>
                        <tr className="tableHeaderbg">
                          <th>Candidate</th>
                          <th>City</th>
                          <th>CV</th>
                          <th>Talent</th>
                          <th>Added</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td className="tableset">1</td>
                          <td className="tableset">1</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

           
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hire;
