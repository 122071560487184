import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Sidebar from "../../Layouts/Sidebar";
import AddRepater from "./Repeater/AddRepater";

const CreateVacancy = () => {
  const [inputFields, setInputFields] = useState([]);

  const addFields = () => {
    let newfield = {
      questionId: 0 + 1,
      questionName: `Question #`,
      question: "",
    };
    setInputFields([...inputFields, newfield]);
  };
  return (
    <>
      <Helmet>
        <title>Create Vacancy - Planit</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Create Vacancy</h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    {/* Nav tabs */}
                    <ul
                      className="nav nav-tabs nav-tabs-custom nav-justified"
                      role="tablist"
                    >
                      <li className="nav-item custome">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#brief"
                          role="tab"
                          aria-selected="true"
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home" />
                          </span>
                          <span className="d-none d-sm-block">
                            Vacancy Details
                          </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#leavepolicy"
                          role="tab"
                          aria-selected="false"
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-user" />
                          </span>
                          <span className="d-none d-sm-block">
                            Questionnaire
                          </span>
                        </a>
                      </li>
                    </ul>
                    {/* Tab panes */}
                    <div className="tab-content p-3 text-muted">
                      <div
                        className="tab-pane active"
                        id="brief"
                        role="tabpanel"
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="validationCustom01"
                              >
                                Vacancy Title
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                placeholder="Vacancy Title"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="validationCustom02"
                              >
                                Available Seats
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom02"
                                placeholder="Available Seats"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="validationCustom03"
                              >
                                Location
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom03"
                                placeholder="Available Seats"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="validationCustom04"
                              >
                                Required Experience (Years)
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom04"
                                placeholder="Required Experience"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="validationCustom05"
                              >
                                Required Education
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom05"
                                placeholder="RRequired Education"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="validationCustom07"
                                  >
                                    Lower Age limit
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="validationCustom07"
                                    placeholder="Lower Age limit"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="validationCustom09"
                                  >
                                    Upper Age limit
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="validationCustom09"
                                    placeholder="Upper Age limit"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="validationCustom07"
                                  >
                                    Start Date
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="validationCustom07"
                                    placeholder="Start Date"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="validationCustom09"
                                  >
                                    Job Expiry Date
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="validationCustom09"
                                    placeholder="Job Expiry Date"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="validationCustom10"
                              >
                                Job Description
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom10"
                                placeholder="Job Description"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label className="font_12 font-weight-bold text-primary-d">
                              Required Gender
                            </label>
                            <br />
                            <div className="form-check form-check-inline font_12  mt-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="req_gender"
                                id="vgenderm"
                                defaultValue={1}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="vgenderm"
                              >
                                Male
                              </label>
                            </div>
                            <div className="form-check form-check-inline font_12 mt-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="req_gender"
                                id="vgenderf"
                                defaultValue={0}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="vgenderf"
                              >
                                Female
                              </label>
                            </div>
                            <div className="form-check form-check-inline font_12 mt-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="req_gender"
                                id="vgenderb"
                                defaultValue={2}
                                defaultChecked=""
                              />
                              <label
                                className="form-check-label "
                                htmlFor="vgenderb"
                              >
                                Both
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label className="font_12 font-weight-bold text-primary-d">
                              Job Type
                            </label>
                            <br />
                            <div className="form-check form-check-inline font_12  mt-2">
                              <input
                                className="form-check-input vacancy_type"
                                type="radio"
                                name="vacancy_type"
                                id="v_remote"
                                defaultValue={2}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="v_remote"
                              >
                                Remote job
                              </label>
                            </div>
                            <div className="form-check form-check-inline font_12 mt-2">
                              <input
                                className="form-check-input vacancy_type"
                                type="radio"
                                name="vacancy_type"
                                id="v_office"
                                defaultValue={1}
                                defaultChecked=""
                              />
                              <label
                                className="form-check-label"
                                htmlFor="v_office"
                              >
                                In Office job
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col text-right">
                          {/* <Link
                            onClick={(e) => goNextTab(e)}
                            className="btn btn-color continue"
                            to="#"
                          >
                            Next
                          </Link> */}
                        </div>
                      </div>
                      <div
                        className="tab-pane"
                        id="leavepolicy"
                        role="tabpanel"
                      >
                        <div className="row">
                          <AddRepater
                            inputFields={inputFields}
                            addFields={addFields}
                          />
                          {/* <div className="col text-right">
                          <Link
                            onClick={(e) => goNextTab(e)}
                            className="btn btn-color continue"
                            to="#"
                          >
                            Next
                          </Link>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateVacancy;
