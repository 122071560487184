import requests from "./httpService";

const LeaveApplicationService = {
  getLeaveType(id, body) {
    return requests.get(`/leavetypes/all?company_id=${id}`, body);
  },
  createLeaveType(body) {
    return requests.post(`/leavetypes/create`, body);
  },
  updateLeaveType(id, body) {
    return requests.post(`/leavetypes/update/${id}`, body);
  },
  deleteLeaveType(id, body) {
    return requests.delete(`/leavetypes/${id}`, body);
  },
  applyLeave(body) {
    return requests.post("/leaveapplications/create", body);
  },
  getLeaveApplication(id, body) {
    return requests.get(`/leaveapplications/all?company_id=${id}`, body);
  },
  getLeaveApplicationHead(depid, id, body) {
    return requests.get(`/leaveapplications/deptusers?company_id=${id}&deptId=${depid}`, body);
  },
  getLeaveApplicationUser(uid, id, body) {
    return requests.get(
      `/leaveapplications/all?company_id=${id}&user_id=${uid}`,
      body
    );
  },
  leaveStatusUpdate(id, body) {
    return requests.post(`/leaveapplications/statusupdate/${id}`, body);
  },
  userLeave(id, comid, body) {
    return requests.get(
      `/leaveapplications/userLeaves/${id}?company_id=${comid}`,
      body
    );
  },
  userLeaveByApplication(id, comid, body) {
    return requests.get(
      `/leaveapplications/applicationLeaves/${id}?company_id=${comid}`,
      body
    );
  },
  userLeaveTypeUpdate(id, body) {
    return requests.post(`leaveapplications/leavestatusupdate/${id}`, body);
  },
};

export default LeaveApplicationService;
