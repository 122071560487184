import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../Layouts/Sidebar";
import { paginate } from "../../../utlits/Paginate.js";
import Pagination from "../../Reuseable/Pagination";
import secureLocalStorage from "react-secure-storage";
import LeaveEntitlementService from "../../../Services/LeaveEntitlementService";
import DataParser from "../../../utlits/Dataparser";
import LeaveApplicationService from "../../../Services/LeaveApplicationService";
import moment from "moment";
import StatusBadgeHr from "../../Reuseable/StatusBadgeHr";
import AddLeavesHr from "./AddLeavesHr";
import UserProfile from "../../Reuseable/UserProfile.";

const LeavesHead = () => {
  const navigate = useNavigate();
  const adminInfo = JSON.parse(secureLocalStorage.getItem("adminInfo"));
  const role = adminInfo?.user?.userdata?.role_title;

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [DataForEmployee, setDataForEmployee] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  // Serach states
  const [userEntitlementList, setUserEntitlementsList] = useState([]);
  const [userLeaveCheck, setUserLeavesCheck] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [leaveStatus, setLeaveStatus] = useState("");
  const [leaveFrom, setLeaveFrom] = useState("");
  const [leaveTo, setLeaveTo] = useState("");
  const compid = DataParser.CompanyId();
  const userid = DataParser.UserId();
  const depid = DataParser.departmentId();

  const getUserEntitlements = () => {
    LeaveEntitlementService.userLeaveEntitlement(userid, compid).then((res) => {
      const Data = res.data.entitlement;
      setUserEntitlementsList(Data);
    });
  };

  useEffect(() => {
    getLeaveApplicationsAll();
    getUserEntitlements();
    userLeaves();
  }, []);

  useEffect(() => {
    if (pageSize > 10) {
      setCurrentPage(1);
    }
  }, [pageSize]);

  const DataForAdmin = [
    {
      Employee: "Employee Admin First",
      LeaveType: "Leave Type",
      From: "From",
      To: "To",
      NoOfDates: "No of Days",
      Reason: "Reason",
      Status: "Status",
    },
    {
      Employee: "Employee Admin",
      LeaveType: "Leave Type",
      From: "From",
      To: "To",
      NoOfDates: "No of Days",
      Reason: "Reason",
      Status: "Status",
    },
    {
      Employee: "Employee Admin",
      LeaveType: "Leave Type",
      From: "From",
      To: "To",
      NoOfDates: "No of Days",
      Reason: "Reason",
      Status: "Status",
    },
    {
      Employee: "Employee Admin",
      LeaveType: "Leave Type",
      From: "From",
      To: "To",
      NoOfDates: "No of Days",
      Reason: "Reason",
      Status: "Status",
    },
    {
      Employee: "Employee Admin",
      LeaveType: "Leave Type",
      From: "From",
      To: "To",
      NoOfDates: "No of Days",
      Reason: "Reason",
      Status: "Status",
    },
    {
      Employee: "Employee Admin",
      LeaveType: "Leave Type",
      From: "From",
      To: "To",
      NoOfDates: "No of Days",
      Reason: "Reason",
      Status: "Status",
    },
    {
      Employee: "Employee Admin",
      LeaveType: "Leave Type",
      From: "From",
      To: "To",
      NoOfDates: "No of Days",
      Reason: "Reason",
      Status: "Status",
    },
    {
      Employee: "Employee Admin",
      LeaveType: "Leave Type",
      From: "From",
      To: "To",
      NoOfDates: "No of Days",
      Reason: "Reason",
      Status: "Status",
    },
    {
      Employee: "Employee Admin",
      LeaveType: "Leave Type",
      From: "From",
      To: "To",
      NoOfDates: "No of Days",
      Reason: "Reason",
      Status: "Status",
    },
    {
      Employee: "Employee Admin",
      LeaveType: "Leave Type",
      From: "From",
      To: "To",
      NoOfDates: "No of Days",
      Reason: "Reason",
      Status: "Status",
    },
    {
      Employee: "Employee Admin",
      LeaveType: "Leave Type",
      From: "From",
      To: "To",
      NoOfDates: "No of Days",
      Reason: "Reason",
      Status: "Status",
    },
    {
      Employee: "Employee Admin",
      LeaveType: "Leave Type",
      From: "From",
      To: "To",
      NoOfDates: "No of Days",
      Reason: "Reason",
      Status: "Status",
    },
    {
      Employee: "Employee Admin",
      LeaveType: "Leave Type",
      From: "From",
      To: "To",
      NoOfDates: "No of Days",
      Reason: "Reason",
      Status: "Status",
    },
    {
      Employee: "Employee Admin",
      LeaveType: "Leave Type",
      From: "From",
      To: "To",
      NoOfDates: "No of Days",
      Reason: "Reason",
      Status: "Status",
    },
    {
      Employee: "Employee Admin",
      LeaveType: "Leave Type",
      From: "From",
      To: "To",
      NoOfDates: "No of Days",
      Reason: "Reason",
      Status: "Status",
    },
    {
      Employee: "Employee Admin",
      LeaveType: "Leave Type",
      From: "From",
      To: "To",
      NoOfDates: "No of Days",
      Reason: "Reason",
      Status: "Status",
    },
    {
      Employee: "Employee Admin",
      LeaveType: "Leave Type",
      From: "From",
      To: "To",
      NoOfDates: "No of Days",
      Reason: "Reason",
      Status: "Status",
    },
    {
      Employee: "Employee Admin",
      LeaveType: "Leave Type",
      From: "From",
      To: "To",
      NoOfDates: "No of Days",
      Reason: "Reason",
      Status: "Status",
    },
    {
      Employee: "Employee Admin",
      LeaveType: "Leave Type",
      From: "From",
      To: "To",
      NoOfDates: "No of Days",
      Reason: "Reason",
      Status: "Status",
    },
    {
      Employee: "Employee Admin Last",
      LeaveType: "Leave Type",
      From: "From",
      To: "To",
      NoOfDates: "No of Days",
      Reason: "Reason",
      Status: "Status",
    },
  ];

  const handelPageChange = (e, page) => {
    e.preventDefault();
    setCurrentPage(page);
  };

  const getLeaveApplicationsAll = () => {
    LeaveApplicationService.getLeaveApplicationHead(depid,compid).then((res) => {
      console.log("Res: ", res);
      const data = res.data.leave_applications;
      setDataForEmployee(data);
    });
  };

  const userLeaves = () => {
    LeaveApplicationService.userLeave(userid, compid).then((res) => {
      const data = res.data.userLeaves;

      var max = data.reduce(function (a, b) {
        return a > b ? a : b;
      });
      setUserLeavesCheck(max.date);
    });
  };

  let newDataE = [];
  for (let i = 0; i < DataForEmployee.length; i++) {
    const element = DataForEmployee[i];
    newDataE.push(element);
  }
  let newDataA = [];
  for (let i = 0; i < DataForAdmin.length; i++) {
    const element = DataForAdmin[i];
    newDataA.push(element);
  }

  const DataE = paginate(newDataE, currentPage, pageSize);

  const DataA = paginate(newDataA, currentPage, pageSize);

  const filterrSearch = (DataE) => {
    if (searchValue === "") {
      return DataE;
    }
    return DataE.filter((el) =>
      el.LeaveType.toLowerCase().includes(searchValue.toLowerCase())
    );
  };
  const filterSearchForAdmin = (DataA) => {
    if (employeeName === "") {
      return DataA;
    }
    return DataA.filter((el) =>
      el.Employee.toLowerCase().includes(employeeName.toLowerCase())
    );
  };
  const filterbyType = (DataA) => {
    if (leaveType === "") {
      return DataA;
    }
    return DataA.filter((el) => el.LeaveType === leaveType);
  };
  const filterbyStatus = (DataA) => {
    if (leaveStatus === "") {
      return DataA;
    }
    return DataA.filter((el) => el.Status === leaveStatus);
  };
  const filterbyFrom = (DataA) => {
    if (leaveFrom === "") {
      return DataA;
    }
    return DataA.filter((el) => el.From === leaveFrom);
  };
  const filterbyTo = (DataA) => {
    if (leaveTo === "") {
      return DataA;
    }
    return DataA.filter((el) => el.To === leaveTo);
    //return DataA.filter((el) => el.To === leaveTo);
  };

  const allFilter = (DataA) => {
    return filterbyTo(
      filterbyFrom(filterbyStatus(filterbyType(filterSearchForAdmin(DataA))))
    );
  };

  const ClearFilter = (e) => {
    e.preventDefault();
    setEmployeeName("");
    setLeaveType("");
    setLeaveStatus("");
    setLeaveFrom("");
    setLeaveTo("");
  };

  const GotoUser = (e, s) => {
    e.preventDefault();
    //alert(s.user.id)
    navigate(`/${DataParser.CompanyIdentifierRole()}/employe_leaves`, {
      state: { data: s.user.id },
    });
  };

  return (
    <>
      <Helmet>
        <title>Leaves - Planit</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row px-5">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Leaves</h4>
                </div>
              </div>

              <div className="card">
                <div className="card-body">
                  <div className="col-12 d-flex">
                    <div className="col-6">
                      <label>
                        Show&nbsp;
                        <select
                          className="form-select"
                          style={{ width: "auto", display: "inline-block" }}
                          value={pageSize}
                          onChange={(e) => setPageSize(e.target.value)}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="100">100</option>
                        </select>
                        &nbsp; entries
                      </label>
                    </div>
                    <div
                      className="col-6"
                      style={{
                        textAlign: "right",
                        fontWeight: "normal",
                        whiteSpace: "nowrap",
                      }}
                    >
                    </div>
                  </div>

                  <table id="dataEable" className="table mb-0 table-striped">
                    <thead>
                      <tr className="tableHeaderbg">
                        <th>Employee</th>
                        <th>Leave Type</th>
                        <th>From</th>
                        <th>To</th>
                        <th>No of Days</th>
                        <th>Leave Balance</th>
                        <th>Reason</th>
                        <th>Approved</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterrSearch(DataE && DataE).map((el, index) => (
                        <tr
                          key={index}
                          role="row"
                          className={index % 2 === 0 ? "odd" : "even"}
                        >
                          <td
                            className="tableset"
                            onClick={(e) => GotoUser(e, el)}
                          >
                            <UserProfile
                              username={el?.user?.name}
                              userImage={el?.user?.image}
                            />
                            &nbsp;
                            {el?.user?.name}
                          </td>
                          <td className="tableset">
                            {el.leav_type !== null ? el.leav_type.name : "-"}
                          </td>
                          <td className="tableset">
                            {moment(el.from_date).format("DD/MM/YYYY")}
                          </td>
                          <td className="tableset">
                            {moment(el.to_date).format("DD/MM/YYYY")}
                          </td>

                          <td className="tableset">{el.totalleaves}</td>
                          <td className="tableset">{el.remainings_leaves}</td>
                          <td className="tableset">{el.reason}</td>
                          <td className="tableset">
                            <StatusBadgeHr
                              item={el}
                              api={LeaveApplicationService.leaveStatusUpdate}
                            />
                          </td>
                          {/* <td>
                              <Link to="#">
                                <i className="uil-pen iconscolor" />
                              </Link>
                            </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="row">
                    <div className="col-sm-12 col-md-5">
                      <div
                        className="dataEables_info"
                        id="dataEable_info"
                        role="status"
                        aria-live="polite"
                      >
                        Showing {DataE?.length} entries
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-7">
                      <Pagination
                        itemCount={DataForEmployee.length}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        onPageChange={handelPageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeavesHead;
