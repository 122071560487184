import React from "react";
import { Route, Routes } from "react-router-dom";
import Packages from "../Components/Screens/Packages/Packages";
import Dashboard from "../Components/Screens/Home/Dashboard";
import Companies from "../Components/Screens/Companies/Companies";
import Users from "../Components/Screens/Users/Users";
import Roles from "../Components/Screens/Roles/Roles";
import Departments from "../Components/Screens/Departments/Departments";
import CreateDepartment from "../Components/Screens/Departments/CreateDepartment";
import EditDepartment from "../Components/Screens/Departments/EditDepartment";
import CreatePackage from "../Components/Screens/Packages/CreatePackage";
import CreateCompanies from "../Components/Screens/Companies/CreateCompanies";
import EditCompanies from "../Components/Screens/Companies/EditCompanies";
import EditPackage from "../Components/Screens/Packages/EditPackage";
import AddUsers from "../Components/Screens/Users/AddUsers";
import EditUser from "../Components/Screens/Users/EditUser";
import CreateRole from "../Components/Screens/Roles/CreateRole";
import EditRole from "../Components/Screens/Roles/EditRole";
import Home from "../Components/Screens/Home/Home";
import Leads from "../Components/Screens/Leads/Leads";
import PrivateComponent from "../Components/PrivateComponent";
import Tasks from "../Components/Screens/Tasks/Tasks";
import Leaves from "../Components/Screens/Leaves/Leaves";
import Attendance from "../Components/Screens/Attendance/Attendance";
import Modules from "../Components/Screens/Modules/Modules";
import LeadSource from "../Components/Screens/LeadSource/LeadSource";
import LeaveType from "../Components/Screens/LeaveType/LeaveType";
import Holidays from "../Components/Screens/Holidays/Holidays";
import CreateHoliday from "../Components/Screens/Holidays/CreateHoliday";
import EditHoliday from "../Components/Screens/Holidays/EditHoliday";
import Settings from "../Components/Screens/Settings/Settings";
import LeavePeriod from "../Components/Screens/LeavePeriod/LeavePeriod";
import Workflow from "../Components/Screens/Workflows/Workflow";
import WorkflowTasks from "../Components/Screens/Workflows/WorkflowTasks";
import Categories from "../Components/Screens/Categories/Categories";
import CategoryLeadType from "../Components/Screens/Categories/LeadType/CategoryLeadType";
import Communication from "../Components/Screens/Communication/Communication";
import Priority from "../Components/Screens/Priority/Priority";
import JobStatus from "../Components/Screens/JobStatus/JobStatus";
import CreateJobStatus from "../Components/Screens/JobStatus/CreateJobStatus";
import EditJobStatus from "../Components/Screens/JobStatus/EditJobStatus";
import AttendanceEmploye from "../Components/Screens/Attendance/AttendanceEmploye";
import LeaveEntitlementUser from "../Components/Screens/Leave Entitlement/LeaveEntitlementUser";
import LeaveEntitlementHR from "../Components/Screens/Leave Entitlement/LeaveEntitlementHR";
import LeaveEntitlement from "../Components/Screens/Leave Entitlement/LeaveEntitlement";
import LeavesHr from "../Components/Screens/Leaves/LeavesHr";
import UserLeaveHr from "../Components/Screens/UserLeaves/UserLeaveHr";
import UserLeaveEmployee from "../Components/Screens/UserLeaves/UserLeaveEmployee";
import HRSettings from "../Components/Screens/Settings/HRSettings";
import AdminSettings from "../Components/Screens/Settings/AdminSettings";
import LeavesHead from "../Components/Screens/Leaves/LeavesHead";
import AttendanceHr from "../Components/Screens/Attendance/AttendanceHr";
import AddPhysicalPeriod from "../Components/Screens/PhysicalPeriod/AddPhysicalPeriod";
import Policy from "../Components/Screens/Policy/Policy";
import AddPolicy from "../Components/Screens/Policy/AddPolicy";
import EditPolicySimple from "../Components/Screens/Policy/EditPolicySimple";
import Hire from "../Components/Screens/Hire/Hire";
import CreateVacancy from "../Components/Screens/Hire/CreateVacancy";

const Main = () => {
  return (
    <>
      <Routes>
        <Route element={<PrivateComponent />}>
          <Route exact path="/admin/dashboard" element={<Dashboard />} />
          <Route exact path="/:cid/home" element={<Home />} />
          {/* Packages */}
          <Route exact path="/:cid/packages" element={<Packages />} />
          <Route
            exact
            path="/:cid/packages/create"
            element={<CreatePackage />}
          />
          <Route
            exact
            path="/:cid/packages/edit/:id"
            element={<EditPackage />}
          />
          {/* Packages */}
          <Route exact path="/:cid/companies" element={<Companies />} />
          <Route
            exact
            path="/:cid/departments/edit"
            element={<EditDepartment />}
          />
          <Route
            exact
            path="/:cid/companies/create"
            element={<CreateCompanies />}
          />
          <Route
            exact
            path="/:cid/companies/edit"
            element={<EditCompanies />}
          />
          <Route
            exact
            path="/:cid/workflow/tasks/:id"
            element={<WorkflowTasks />}
          />
          <Route exact path="/:cid/users" element={<Users />} />
          <Route exact path="/:cid/users/create" element={<AddUsers />} />
          <Route exact path="/:cid/users/:id" element={<EditUser />} />
          <Route exact path="/:cid/roles" element={<Roles />} />
          <Route exact path="/:cid/roles/create" element={<CreateRole />} />
          <Route exact path="/:cid/roles/edit" element={<EditRole />} />
          <Route exact path="/:cid/jobemplaoye" element={<JobStatus />} />
          <Route exact path="/:cid/departments" element={<Departments />} />
          <Route exact path="/:cid/modules" element={<Modules />} />
          <Route exact path="/:cid/lead_source" element={<LeadSource />} />
          <Route exact path="/:cid/leave_type" element={<LeaveType />} />
          <Route exact path="/:cid/holidays" element={<Holidays />} />
          <Route
            exact
            path="/:cid/holidays/create"
            element={<CreateHoliday />}
          />
          <Route exact path="/:cid/holidays/edit" element={<EditHoliday />} />
          <Route
            exact
            path="/:cid/jobemplaoye/create"
            element={<CreateJobStatus />}
          />
          <Route
            exact
            path="/:cid/jobemplaoye/edit"
            element={<EditJobStatus />}
          />
          <Route
            exact
            path="/:cid/departments/create"
            element={<CreateDepartment />}
          />
          <Route exact path="/:cid/leads" element={<Leads />} />
          <Route exact path="/:cid/tasks" element={<Tasks />} />
          <Route exact path="/:cid/employe_leaves" element={<Leaves />} />
          <Route exact path="/:cid/leaves" element={<LeavesHr />} />
          <Route exact path="/:cid/leaves_head" element={<LeavesHead />} />
          <Route exact path="/:cid/hr" element={<Attendance />} />
          <Route exact path="/:cid/attendance" element={<AttendanceHr />} />
          <Route
            exact
            path="/:cid/employe-attendance"
            element={<AttendanceEmploye />}
          />
          <Route exact path="/:cid/settings" element={<Settings />} />
          <Route exact path="/:cid/adminsettings" element={<AdminSettings />} />
          <Route
            exact
            path="/:cid/leave_entitlement"
            element={<LeaveEntitlementHR />}
          />
          <Route
            exact
            path="/:cid/employee_leave_entitlement"
            element={<LeaveEntitlement />}
          />
          <Route
            exact
            path="/:cid/leave_entitlement/:id"
            element={<LeaveEntitlementUser />}
          />
          <Route exact path="/:cid/leave_period" element={<LeavePeriod />} />
          <Route exact path="/:cid/workflow" element={<Workflow />} />
          <Route exact path="/:cid/categories" element={<Categories />} />
          <Route
            exact
            path="/:cid/categories/lead_types/:id"
            element={<CategoryLeadType />}
          />
          <Route exact path="/:cid/communication" element={<Communication />} />
          <Route exact path="/:cid/priority" element={<Priority />} />
          <Route exact path="/:cid/leaveusers" element={<UserLeaveHr />} />
          <Route
            exact
            path="/:cid/employee_leaveuser"
            element={<UserLeaveEmployee />}
          />
          <Route exact path="/:cid/hr_settings" element={<HRSettings />} />
          <Route
            exact
            path="/:cid/companysettings"
            element={<AddPhysicalPeriod />}
          />

          <Route exact path="/:cid/policy" element={<Policy />} />
          <Route exact path="/:cid/policy/create" element={<AddPolicy />} />
          <Route exact path="/:cid/policy/:id" element={<EditPolicySimple />} />

          <Route exact path="/:cid/hire/" element={<Hire />} />
          <Route exact path="/:cid/hire/create" element={<CreateVacancy />} />
          {/* </Route> */}
        </Route>
      </Routes>
    </>
  );
};

export default Main;
