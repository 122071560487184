import React from "react";
import { Link } from "react-router-dom";

const Career = () => {
  return (
    <>
      <div className="d-flex">
        <div
          className="col-lg-2 col-md-3 col-5 justify-content-start"
          style={{ height: 85 }}
        >
          <div className="row">
            <nav className="navbar">
              <div className="container-fluid">
                <Link className="img-fluid org_logo_div" to="#">
                  <img
                    alt=""
                    className="img-fluid"
                    src="/assets/images/logo-dark.png"
                    id="logo"
                  />
                </Link>
              </div>
            </nav>
          </div>
        </div>
        <div
          className="col-lg-10 col-md-9 col-7 justify-content-end pe-2"
          id="header_icons"
        >
          <div className="d-flex justify-content-end mt-2">
            <div className="px-2" id="user_profile_icon_div">
              <Link to="#" className="">
                <span className="">My Profile</span>
              </Link>
            </div>
            <div className="px-2">
              <Link to="#" className="text-center">
                <i className="fas fa-sign-out-alt ms-2" aria-hidden="true" />
                <span>Logout</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row" style={{ margin: 0 }}>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row p-5">
              <div className="col-lg-10 col-md-10 col-12" id="vacancy-wrapper">
                <b>
                  Available Vacancies at{" "}
                  <span className="org_name_div">VROX</span>
                </b>
                <div
                  id="vacancy-container"
                  onclick="window.location='#specific_vacancy_detail';"
                >
                  <div
                    className="card mb-3 mt-3 gap-3 vacancy_list show_vacancy_details"
                    data-id={0}
                  >
                    <div className="card-body d-flex d-inline place-item-center">
                      <i className="fas fa-briefcase" aria-hidden="true" />
                      <span className="px-3">Nojs developer</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div
              className="float-end initial_vacancy_img "
              style={{ display: "none" }}
            >
              <img
                src="/assets/images/logo-dark.png"
                className="img-fluid"
                alt="..."
              />
            </div>
            <div
              className="specific_vacancy_detail ps-3"
              id="specific_vacancy_detail"
              style={{}}
            >
              <div className="row col mb-3 mt-5">
                <div className="col-8">
                  <p
                    className="fw-bold"
                    id="vacancy-title"
                    style={{ fontSize: 18 }}
                  >
                    Nojs developer
                  </p>
                </div>
                <div
                  className="col-4 d-flex justify-content-end"
                  id="apply_vacancy_div"
                >
                  <Link
                    to="#"
                    className="btn btn-primary font_12  fw-bold apply_for_vacancy_btn"
                    type="submit"
                    id="apply_for_vacancy"
                  >
                    Apply
                  </Link>
                </div>
              </div>
              <div className="row mb-3 mt-4">
                <div className="col-lg-4 col-md-4 d-flex flex-row mobile-space">
                  <div className="d-flex vacancy_icon">
                    <i className="fas fa-venus-mars" aria-hidden="true" />
                  </div>
                  <div className="px-2">
                    <div className="fw-bold my_blue font_12">Gender</div>
                    <div className="font_14 " id="gender">
                      Both
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 d-flex flex-row mobile-space">
                  <div
                    className="d-flex vacancy_icon"
                    style={{ padding: "10px 11px" }}
                  >
                    <i className="fas fa-users" aria-hidden="true" />
                  </div>
                  <div className="px-2">
                    <div className="fw-bold my_blue font_12">Age Limit</div>
                    <div className="font_14" id="age-limit">
                      20 - 45
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 d-flex flex-row">
                  <div className="d-flex vacancy_icon">
                    <i className="fas fa-user-tie" aria-hidden="true" />
                  </div>
                  <div className="px-2">
                    <div className="fw-bold my_blue font_12">Job Type</div>
                    <div className="font_14" id="job-type">
                      Office Job
                    </div>
                  </div>
                </div>
              </div>
              <hr className="my-2" />
              <div className="row mb-3 mt-4">
                <div className="col-lg-4 col-md-4 d-flex flex-row mobile-space">
                  <div
                    className="d-flex vacancy_icon"
                    style={{ padding: "10px 15px" }}
                  >
                    <i className="fas fa-chair" aria-hidden="true" />
                  </div>
                  <div className="px-2">
                    <div className="fw-bold my_blue font_12">Total Seats</div>
                    <div className="font_14" id="total-seats">
                      1
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 d-flex flex-row mobile-space">
                  <div className="d-flex vacancy_icon">
                    <i className="far fa-calendar" aria-hidden="true" />
                  </div>
                  <div className="px-2">
                    <div className="fw-bold my_blue font_12">Deadline Date</div>
                    <div className="font_14" id="deadline-date">
                      Undisclosed
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 d-flex flex-row">
                  <div className="d-flex vacancy_icon">
                    <i className="far fa-flag" aria-hidden="true" />
                  </div>
                  <div className="px-2">
                    <div className="fw-bold my_blue font_12">Experience</div>
                    <div className="font_14" id="experience">
                      1 year(s)
                    </div>
                  </div>
                </div>
              </div>
              <hr className="my-2" />
              <div className="row mb-3 mt-4">
                <div className="col-lg-4 col-md-4 d-flex flex-row mobile-space">
                  <div
                    className="d-flex vacancy_icon"
                    style={{ padding: "10px 15px" }}
                  >
                    <i className="fas fa-map-marker-alt" aria-hidden="true" />
                  </div>
                  <div className="px-2">
                    <div className="fw-bold my_blue font_12">Location</div>
                    <div className="font_14" id="location">
                      Abbottabad
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-8 d-flex flex-row">
                  <div className="d-flex vacancy_icon">
                    <i className="fas fa-graduation-cap" aria-hidden="true" />
                  </div>
                  <div className="px-2">
                    <div className="fw-bold my_blue font_12">
                      Required Education
                    </div>
                    <div className="text-wrap font_14" id="req-education">
                      engineerr
                    </div>
                  </div>
                </div>
              </div>
              <hr className="my-2" />
              <div className="row mb-3 mt-4">
                <div className="col-12 d-flex flex-row">
                  <div className="d-flex vacancy_icon">
                    <i className="far fa-file-alt" aria-hidden="true" />
                  </div>
                  <div className="px-2 pb-5">
                    <div className="fw-bold my_blue font_12">Description</div>
                    <div className="font_14" id="description">
                      asd asd asd as das das da sd as das das dasd
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Career;
