import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../Layouts/Sidebar";
import Papa from "papaparse";
import DataParser from "../../../utlits/Dataparser";
import AttendanceService from "../../../Services/AttendanceService";
import UserProfile from "../../Reuseable/UserProfile.";
import CompanyService from "../../../Services/CompanyService";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import moment from "moment";

const Attendance = () => {
  const navigate = useNavigate();
  const compId = DataParser.CompanyId();
  const userid = DataParser.UserId();
  const daysInMonth = DataParser.getDaysInMonth();
  const currentDate = DataParser.getDateinFormat();

  const [parsedData, setParsedData] = useState([]);

  const [tableRows, setTableRows] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [values, setValues] = useState([]);

  const [attendence, setAttendence] = useState([]);
  const [users, setUsers] = useState([]);

  const [employeeid, setEmployeeid] = useState("");
  const [selectMonth, setSelectMonth] = useState("");
  const [selectYear, setSelectYear] = useState("");

  useEffect(() => {
    let numberOfDays = ["Employee"];

    for (let i = 1; i <= daysInMonth; i++) {
      numberOfDays.push(i);
    }
    setTableRows(numberOfDays);

    getAttendence();
    getAllCompanyUsers();
  }, []);

  const getAttendence = () => {
    setLoading(true);
    AttendanceService.getAttendanceHr(compId).then((res) => {
      const data = res.data.attandence;
      setAttendence(data);
      setLoading(false);
    });
  };

  const getAllCompanyUsers = () => {
    CompanyService.getComapnyUser(compId).then((res) => {
      const users = res.data.user;
      setUsers(users);
    });
  };

  const changeHandler = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];
        results.data
          .filter((els) => els.employee_Id !== "")
          .map((d) => {
            rowsArray.push(Object.keys(d));
            let obj = {
              empid: parseInt(Object.values(d)[1]),
              date: Object.values(d)[0],
              timeIn: Object.values(d)[2],
              timeOut: Object.values(d)[3],
            };
            valuesArray.push(obj);
          });
        setParsedData(results.data);

        setValues(valuesArray);

        const companyid = DataParser.CompanyId();
        const Data = {
          company_id: companyid,
          data: valuesArray,
        };
        AttendanceService.uploadAttendance(Data).then((res) => {});
      },
    });
  };

  const handelSearch = (e) => {
    e.preventDefault();
    setLoading(true);
    AttendanceService.getAttendancebySearch(
      compId,
      employeeid,
      selectMonth,
      selectYear
    ).then((res) => {
      const Data = res.data.attandence;

      const daysInJanuary = DataParser.getDaysInMonthss(
        selectYear,
        selectMonth
      );
      //setting months
      let numberOfDays = ["Employee"];
      for (let i = 1; i <= daysInJanuary; i++) {
        numberOfDays.push(i);
      }
      setTableRows(numberOfDays);
      setAttendence(Data);
      setSelectMonth("");
      setSelectYear("");
      setEmployeeid("");
      setLoading(false);
    });
  };

  const handelCheckAttendance = (e, s) => {
    e.preventDefault();
    const name = s.name;
    navigate(`/${DataParser.CompanyIdentifierRole()}/employe-attendance`, {
      state: { data: s.id, name: name },
    });
  };

  const AttendanceManage = (elsss) => {
    if (
      (elsss.holiday === true) &
      (elsss.present === false) &
      (elsss.leave === false)
    ) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 50 50"
          width={14}
          height={14}
        >
          <path d="M42 47h-7a1 1 0 01-1-1V28H16v18a1 1 0 01-1 1H8a1 1 0 01-1-1V4a1 1 0 011-1h7a1 1 0 011 1v16h18V4a1 1 0 011-1h7a1 1 0 011 1v42a1 1 0 01-1 1z" />
        </svg>
      );
    } else if (
      (elsss.holiday === false) &
      (elsss.present === false) &
      (elsss.leave === true)
    ) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 50 50"
          width={14}
          height={14}
        >
          <path
            d="M18 3h-7a1 1 0 00-1 1v42a1 1 0 001 1h28a1 1 0 001-1v-6a1 1 0 00-1-1H19V4a1 1 0 00-1-1z"
            fill="#0075C6"
          />
        </svg>
      );
    } else if (
      (elsss.holiday === false) &
      (elsss.present === true) &
      (elsss.leave === false)
    ) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 50 50"
          width={14}
          height={14}
        >
          <path
            d="M26.455 3.025H9a1 1 0 00-1 1V46a1 1 0 001 1h7a1 1 0 001-1V33h9.125C35.472 33 42 26.78 42 17.875c0-8.882-6.247-14.85-15.545-14.85zM24.459 26H17V10h7.488C29.929 10 33 12.815 33 18.059 33 23.156 29.841 26 24.459 26z"
            fill="#00A650"
          />
        </svg>
      );
    } else {
      if (
        moment(elsss.date, "DD-MM-YYYY").format("DD/MM/YYYY") >=
        moment().format("DD/MM/YYYY")
      ) {
        if (moment(elsss.date, "DD-MM-YYYY").month() < moment().month()) {
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 50"
              width={14}
              height={14}
            >
              <path
                d="M44.943 45.668l-14.758-42A1 1 0 0029.242 3h-8.483a1 1 0 00-.943.668l-14.758 42A.997.997 0 006 47h6.375a.999.999 0 00.943-.669L16.949 36h16.103l3.63 10.331a1 1 0 00.943.669H44a1 1 0 00.943-1.332zM24.679 14h.641l5.271 15H19.408l5.271-15z"
                fill="#F46A6A"
              />
            </svg>
          );
        } else {
          return "-";
        }
      } else {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
            width={14}
            height={14}
          >
            <path
              d="M44.943 45.668l-14.758-42A1 1 0 0029.242 3h-8.483a1 1 0 00-.943.668l-14.758 42A.997.997 0 006 47h6.375a.999.999 0 00.943-.669L16.949 36h16.103l3.63 10.331a1 1 0 00.943.669H44a1 1 0 00.943-1.332zM24.679 14h.641l5.271 15H19.408l5.271-15z"
              fill="#F46A6A"
            />
          </svg>
        );
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Attendance - Planit</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row px-5">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Attendance</h4>
                  <div className="page-title-right">
                    <label htmlFor="file-input-notes">
                      <p htmlFor="file-input-notes">
                        <span
                          style={{
                            backgroundColor: "#00b5b8",
                            borderColor: "#00b5b8",
                            color: "white",
                          }}
                          type="button"
                          className="btn btn-color waves-effect waves-light"
                        >
                          Upload Attendance
                        </span>
                      </p>
                    </label>
                    <input
                      type="file"
                      name="file"
                      id="file-input-notes"
                      accept=".csv"
                      onChange={(e) => {
                        changeHandler(e);
                      }}
                      style={{ display: "none" }}
                    />
                    <a
                      style={{ marginLeft: "10px" }}
                      href="/assets/sampleData.csv"
                      download="Attandance Upload Sample"
                      target="_blank"
                    >
                      <button className="btn btn-color waves-effect waves-light">
                        Download Sample
                      </button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-sm-6 col-md-3">
                  <div className="form-group form-focus">
                    <select
                      required
                      value={employeeid}
                      onChange={(e) => setEmployeeid(e.target.value)}
                      className="form-select"
                    >
                      <option value="">Select Employee Name</option>
                      {users.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.first_name} {user.last_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <select
                    required
                    value={selectMonth}
                    onChange={(e) => setSelectMonth(e.target.value)}
                    className="form-select"
                  >
                    <option value="">Select Month</option>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>
                <div className="col-sm-6 col-md-3">
                  <select
                    required
                    value={selectYear}
                    onChange={(e) => setSelectYear(e.target.value)}
                    className="form-select"
                  >
                    <option value="">Select Year</option>
                    <option value="2023">2023</option>
                    <option value="2022">2022</option>
                  </select>
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="d-grid">
                    <Link
                      style={{
                        backgroundColor: "#00b5b8 !important",
                        borderColor: "#00b5b8 !important",
                        color: "white !important",
                      }}
                      to="#"
                      onClick={(e) => handelSearch(e)}
                      className="btn btn-color"
                    >
                      Search
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      {isLoading ? (
                        <>
                          <Skeleton width="100%" />
                          <Skeleton width="100%" />
                          <Skeleton width="100%" />
                        </>
                      ) : (
                        <table className="table mb-0 table-striped">
                          <thead>
                            <tr className="tableHeaderbg">
                              {tableRows.map((els) => (
                                <th key={els}>{els}</th>
                              ))}
                            </tr>
                          </thead>

                          <tbody>
                            {attendence.map((el) => (
                              <tr key={el.id}>
                                <td
                                  className="tableset"
                                  onClick={(e) => handelCheckAttendance(e, el)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <UserProfile
                                    username={el?.name}
                                    userImage={el?.image}
                                  />
                                  &nbsp; &nbsp;
                                  {el?.name}
                                </td>
                                {el.attandence.map((elsss) => (
                                  <td className="tableset" key={elsss.date}>
                                    {/* {elsss.date <=
                                  moment(currentDate).format("DD/MM/YYYY") ? (
                                    <>
                                    {
                                      AttendanceManage(elsss)
                                    }
                                      
                                    </>
                                  ) : (
                                    "-"
                                  )} */}

                                    {AttendanceManage(elsss)}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Attendance;
