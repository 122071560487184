import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Fade } from "react-reveal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DataParser from "../../../utlits/Dataparser";
import moment from "moment/moment";
import Sidebar from "../../Layouts/Sidebar";
import CreateTask from "./CreateTask";
import TaskServices from "../../../Services/TaskServices";
import DateField from "../../Reuseable/DateField";
import AssigneField from "../../Reuseable/AssigneField";
import LeadPendingField from "../../Reuseable/LeadPendingField";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import EditTasks from "./EditTask";
import secureLocalStorage from "react-secure-storage";

const Tasks = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dateLoader, setDateLoader] = useState(false);
  const [currentDateFormat, setCurrentDateFormat] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [sortFilter, setSortFilter] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editDrawerOpen, setEditDrawerOpen] = useState(false);
  const [tasksAssignetByME, setTaskAssignetByME] = useState([]);
  const [allOtherTasks, setllOtherTasks] = useState([]);
  const [tasksAssignetToME, setTaskAssignetToME] = useState([]);
  const [taskOwner, setTaskOwner] = useState([]);
  const [taskStatus, setTaskStatus] = useState(null);
  const [taskPriority, setTaskPriority] = useState([]);
  const [editId, setEditId] = useState("");
  const [taskSt, settaskSt] = useState("");
  const [taskFilter, setTaskFilter] = useState("");
  const [userPermissions, setuserPermissions] = useState([]);
  const companyId = DataParser.CompanyId();
  const companyIdentifier = DataParser.CompanyIdentifier();

  const [title, setTitle] = useState("");
  const [assignedTo, setAssignedTo] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [periority, setPeriority] = useState("");
  const [approval, setApproval] = useState("");
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");
  const [editLoading, setEditLoading] = useState(false);
  const [UpdateNotesAttac, setUpdateNotesAttac] = useState(null);

  const [TaskData, setTaskData] = useState(null);
  const [TaskViewAttachment, setTaskViewAttachment] = useState(null);
  const [isUploding, setIsUploding] = useState(null);

  const [owner, setOwner] = useState(null);
  const [TaskNote, setTasKNote] = useState(null);
  const [TaskNotification, setTaskNotification] = useState(null);
  const [updatingNotes, setUpdatingNotes] = useState(false);

  const companyIdetifier = DataParser.CompanyIdentifier();

  const reSetData = () => {
    setLoading(true);
    setIsLoading(true);
    TaskServices.getAllTask(companyId).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
      const data = res.data;

      setTaskAssignetToME(data.tasks_assigned_to_me);
      setTaskAssignetByME(data.tasksByme);
      setTaskStatus(data.status);
      setTaskOwner(data.task_owner);
      if (data.all_other_tasks !== "undefined") {
        setllOtherTasks(data.all_other_tasks);
      }
      setTaskPriority(data.priority);
      settaskSt(data.status);
      setLoading(false);
      setDateLoader(false);
      setIsLoading(false);
    });
  };

  const handleSubmit = (e, id) => {
    e.preventDefault();
    setDateLoader(true);
    const newDate = moment(dueDate).format("YYYY-MM-DD");
    const body = {
      company_id: companyId,
      company_identifier: companyIdentifier,
      title: title,
      assign_to: assignedTo,
      due_date: newDate,
      priority: periority,
      approval_required: approval,
      status: status,
      description: description,
    };
    TaskServices.updateTask(id, body)
      .then((res) => {
        reSetData(e);
      })
      .catch((err) => console.log("Error Failed"));
  };

  const handleUpdaut = (e) => {
    e.preventDefault();
    TaskServices.getAllTask(companyId).then((res) => {
      if (res.data === "Un-Athunticated request") {
        secureLocalStorage.removeItem("adminInfo");
        secureLocalStorage.removeItem("admins");
        navigate("/");
      }
      const data = res.data;
      setTaskAssignetByME(data.tasksByme);
      setTaskStatus(data.status);
      setTaskOwner(data.task_owner);
      setllOtherTasks(data.all_other_tasks);
      setTaskAssignetToME(data.tasks_assigned_to_me);
      setTaskPriority(data.priority);
      setLoading(false);
    });
  };

  const handelEditDrawerForNotification = (id) => {
    setEditId(id);
    setEditDrawerOpen(!editDrawerOpen);
    reSetData();
  };

  useEffect(() => {
    reSetData();
    const datee = DataParser.getDateinFormat();
    const upermission = DataParser.CompanyUserPermissions();
    setuserPermissions(upermission);
    setCurrentDateFormat(datee);
    setLoading(false);
    if (location?.state?.isNotification) {
      const id = location?.state?.TaskId;
      handelEditDrawerForNotification(id);
    }

    if (location?.state?.isProjectTask) {
      setEditId(location?.state?.id);
      setEditDrawerOpen(true);
    }
  }, [sortFilter]);

  useEffect(() => {
    if (location?.state?.isTasks) {
      setDrawerOpen(true);
      isElementLoaded("#createTaskss").then((selector) => {
        openTaskCreate();
      });
    }
    return () => {
      console.log("hi");
    };
  }, [location]);

  const handelDrawer = (e) => {
    e.preventDefault();
    setDrawerOpen(!drawerOpen);
  };
  const handelEditDrawer = (e, id) => {
    e.preventDefault();
    setEditLoading(true);
    setEditId(id);
    getData(id);
    TaskNotes(id);
    TaskNotifications(id);
    TaskNotesAttachmentImages(id);
    TaskAttachmentImages(id);
  };

  const setComplete = (e, item) => {
    e.preventDefault();

    const id = item.id;
    const companyId = DataParser.CompanyId();

    const dataLead = {
      company_id: companyId,
      company_identifier: companyIdentifier,
      status: "complete",
    };
    TaskServices.updateTaskStatus(id, dataLead).then((res) => {
      setTimeout(() => {
        const re = reSetData();
        settaskSt("complete");
      }, 2000);
    });
  };

  const filterrSearch = (data) => {
    if (searchValue === "") {
      return data;
    }
    return data.filter((el) =>
      el.title.toLowerCase().includes(searchValue.toLowerCase())
    );
  };
  const filterbyStatus = (data) => {
    if (taskFilter === "") {
      return data;
    }
    return data.filter((el) => el.status === taskFilter);
  };
  const sortingByDifferent = (data) => {
    if (sortFilter === "") {
      return data;
    }
    if (sortFilter === "due-date") {
      return data.sort(
        (a, b) => moment(b.due_date).valueOf() - moment(a.due_date).valueOf()
      );
    }
    if (sortFilter === "assignee") {
      let a = data.sort((a, b) =>
        a.task_assigned_to.first_name.localeCompare(
          b.task_assigned_to.first_name
        )
      );
      // console.log("value of a is: ", a);
      return data.sort((a, b) =>
        a.task_assigned_to.first_name.localeCompare(
          b.task_assigned_to.first_name
        )
      );
      // return data.sort((p1, p2) =>
      //   p1.task_assigned_to.first_name < p2.task_assigned_to.first_name
      //     ? 1
      //     : p1.task_assigned_to.first_name > p2.task_assigned_to.first_name
      //     ? -1
      //     : 0
      // );
    }
    if (sortFilter === "status") {
      return data.sort((p1, p2) =>
        p1.status < p2.status ? 1 : p1.status > p2.status ? -1 : 0
      );
    }
  };
  const allFilter = (data) => {
    return sortingByDifferent(filterbyStatus(filterrSearch(data)));
  };

  //task edit fun

  const getData = (id) => {
    const body = {
      company_id: companyId,
    };
    TaskServices.viewTask(id, body)
      .then((res) => {
        const data = res.data.task;
        setTaskData(data);
        setTitle(data.title);
        setAssignedTo(data.asigned_to);
        setDueDate(data.due_date);
        setPeriority(data.priority);
        setApproval(data.approval_required);
        setStatus(data.status);
        setDescription(data.description);
        setOwner(data.created_by);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const TaskNotes = (id) => {
    const body = {
      company_id: companyId,
    };
    TaskServices.viewTask(id, body).then((res) => {
      const response = res.data.task.task_notes;
      setTasKNote(response);
    });
  };

  const TaskNotifications = (id) => {
    TaskServices.viewTask(id, { company_id: companyId }).then((res) => {
      const response = res.data.task;
      setTaskNotification(response.task_notifications);
    });
  };

  const handelDeleteNote = (e, ids) => {
    e.preventDefault();

    const data = {
      company_id: companyId,
      company_identifier: companyIdentifier,
    };
    TaskServices.deleteTaskNote(ids, data).then((res) => {
      TaskNotes();
      setUpdatingNotes(!updatingNotes);
    });
  };

  const TaskNotesAttachmentImages = (id) => {
    TaskServices.viewTask(id).then((res) => {
      const response = res.data.task;
      setUpdateNotesAttac(response.task_notes_attachments);
    });
  };

  const TaskAttachmentImages = (id) => {
    TaskServices.viewTask(id).then((res) => {
      const response = res.data.task;

      setIsUploding(false);
      setTaskViewAttachment(response.task_attachments);
    });
  };

  const openTaskCreate = () => {
    document.querySelector("#createTaskss").click();
    navigate(`/${companyIdetifier}/tasks`, {
      state: null,
    });
  };

  const isElementLoaded = async (selector) => {
    while (document.querySelector(selector) === null) {
      await new Promise((resolve) => requestAnimationFrame(resolve));
    }
    return document.querySelector(selector);
  };

  return (
    <>
      <Helmet>
        <title>Tasks - Planit</title>
      </Helmet>
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <CreateTask
              id={"offcanvasRight"}
              labelledby={"offcanvasRightLabel"}
              setDrawerOpen={setDrawerOpen}
              priority={taskPriority}
              statuses={taskStatus}
              taskOwner={taskOwner}
              handelDrawer={(e) => handelDrawer(e)}
              reFreshData={(e) => reSetData(e)}
            />

            <>
              {userPermissions.indexOf("tasks-edit") !== -1 ? (
                <>
                  <EditTasks
                    mid={`offcanvasRight2`}
                    labelledby={`offcanvasRightLabel2`}
                    setTaskAssignetToME={setTaskAssignetToME}
                    tasksAssignetToME={tasksAssignetToME}
                    setDrawerOpen={editDrawerOpen}
                    priority={taskPriority}
                    reFreshData={(e) => reSetData(e)}
                    statuses={taskStatus}
                    taskOwner={taskOwner}
                    handelDrawer={(e) => handelEditDrawer(e)}
                    EditId={editId}
                    title={title}
                    assignedTo={assignedTo}
                    dueDate={dueDate}
                    periority={periority}
                    approval={approval}
                    status={status}
                    description={description}
                    setTitle={setTitle}
                    setAssignedTo={setAssignedTo}
                    setDueDate={setDueDate}
                    setPeriority={setPeriority}
                    setApproval={setApproval}
                    setStatus={setStatus}
                    setDescription={setDescription}
                    userPermissions={userPermissions}
                    TaskData={TaskData}
                    TaskViewAttachment={TaskViewAttachment}
                    setTaskViewAttachment={setTaskViewAttachment}
                    owner={owner}
                    editLoading={editLoading}
                    setEditLoading={setEditLoading}
                    TaskNotification={TaskNotification}
                    TaskNote={TaskNote}
                    setOwner={setOwner}
                    setUpdatingNotes={setUpdatingNotes}
                    updatingNotes={updatingNotes}
                    TaskNotes={(e) => TaskNotes(e)}
                    handelDeleteNote={(e, ids) => handelDeleteNote(e, ids)}
                    handleSubmit={handleSubmit}
                    UpdateNotesAttac={UpdateNotesAttac}
                    setUpdateNotesAttac={setUpdateNotesAttac}
                    TaskNotesAttachmentImages={TaskNotesAttachmentImages}
                    isUploding={isUploding}
                    setIsUploding={setIsUploding}
                    TaskAttachmentImages={TaskAttachmentImages}
                    TaskNotifications={TaskNotifications}
                  />
                </>
              ) : (
                ""
              )}
            </>

            <div className="row">
              <div className="col-3">
                {userPermissions.indexOf("tasks-create") !== -1 ? (
                  <Link
                    to="#"
                    id="createTaskss"
                    className="btnsss"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                  >
                    <i className="uil-plus" /> New Tasks
                  </Link>
                ) : (
                  ""
                )}
                {/* <Link
                  to="#"
                  id="createTaskss"
                  className="btnsss"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                >
                  <i className="uil-plus" /> New Tasks
                </Link> */}
              </div>
              <div className="col-9">
                <div className="float-end">
                  <form className="d-inline-flex ">
                    <label
                      className="form-check-label my-2 me-2"
                      htmlFor="order-selectinput"
                    >
                      <i className="uil-filter"></i>
                    </label>
                    <select
                      className="form-select formselectset"
                      id="order-selectinput"
                      style={{
                        border: "none",
                      }}
                      onChange={(e) => setSortFilter(e.target.value)}
                    >
                      <option selected="none">
                        <Link to="#" id="none" className="dropdown-item">
                          Sort
                        </Link>
                      </option>
                      <option value="none">None</option>
                      <option value="due-date">Due Date</option>
                      <option value="assignee">Asignee</option>
                      <option value="status">Status</option>
                    </select>
                  </form>
                  <select
                    style={{ border: "none" }}
                    className="init"
                    value={taskFilter}
                    onChange={(e) => setTaskFilter(e.target.value)}
                  >
                    <option value="">All Task</option>
                    <option value="pending">Pending</option>
                    <option value="in-progress">In Progress </option>
                    <option value="on-hold">On Hold </option>
                    <option value="pending_approval">Pending Approval</option>
                    <option value="rejected">Rejected</option>
                    <option value="complete">Completed</option>
                  </select>
                </div>
              </div>
            </div>

            <form className="app-search">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  style={{ border: "none" }}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <span className="uil-search" />
              </div>
            </form>

            <div className="todo-task-list list-group ps w-100">
              <div className="task_heading_column">
                <div className="task_name_column">
                  <p>Title</p>
                </div>
                <div className="Projects_name_column">
                  <p>Status</p>
                </div>
                <div className="Projects_name_column ">
                  <p>Assignee</p>
                </div>
                <div className="Projects_name_column">
                  <p>Project</p>
                </div>
                <div className="date_name_column">
                  <p>Due Date</p>
                </div>
              </div>
            </div>

            {isLoading ? (
              <>
                <div>
                  <div className="todo-task-list list-group ps w-100">
                    <div className="task_heading_column">
                      <div className="task_name_column">
                        <div className="form-check mt-4 w-100 h-100">
                          <Skeleton width="50%" />
                        </div>
                      </div>
                      <div className="Projects_name_column">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                      <div className="Projects_name_column ">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                      <div className="Projects_name_column">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                      <div className="date_name_column">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="todo-task-list list-group ps w-100">
                    <div className="task_heading_column">
                      <div className="task_name_column">
                        <div className="form-check mt-4 w-100 h-100">
                          <Skeleton width="50%" />
                        </div>
                      </div>
                      <div className="Projects_name_column">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                      <div className="Projects_name_column ">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                      <div className="Projects_name_column">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                      <div className="date_name_column">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="todo-task-list list-group ps w-100">
                    <div className="task_heading_column">
                      <div className="task_name_column">
                        <div className="form-check mt-4 w-100 h-100">
                          <Skeleton width="50%" />
                        </div>
                      </div>
                      <div className="Projects_name_column">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                      <div className="Projects_name_column ">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                      <div className="Projects_name_column">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                      <div className="date_name_column">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="todo-task-list list-group ps w-100">
                    <div className="task_heading_column">
                      <div className="task_name_column">
                        <div className="form-check mt-4 w-100 h-100">
                          <Skeleton width="50%" />
                        </div>
                      </div>
                      <div className="Projects_name_column">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                      <div className="Projects_name_column ">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                      <div className="Projects_name_column">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                      <div className="date_name_column">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="todo-task-list list-group ps w-100">
                    <div className="task_heading_column">
                      <div className="task_name_column">
                        <div className="form-check mt-4 w-100 h-100">
                          <Skeleton width="50%" />
                        </div>
                      </div>
                      <div className="Projects_name_column">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                      <div className="Projects_name_column ">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                      <div className="Projects_name_column">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                      <div className="date_name_column">
                        <Skeleton style={{ width: "90%" }} />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <Fade big>
                <>
                  {allFilter(tasksAssignetToME && tasksAssignetToME)?.filter(
                    (el) =>
                      moment().format("YYYY-MM-DD") ===
                      moment(el.due_date).format("YYYY-MM-DD")
                  ).length === 0 ? null : (
                    <Fade big>
                      <h2 className="added_by_me" id="headingOne">
                        <span
                          className=""
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <i className="uil-arrow-down" /> Due Today
                        </span>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        {allFilter(
                          tasksAssignetToME &&
                            tasksAssignetToME?.filter(
                              (el) =>
                                moment().format("YYYY-MM-DD") ===
                                moment(el.due_date).format("YYYY-MM-DD")
                            )
                        )?.map((item, index) => (
                          <div
                            className="todo-task-list list-group ps w-100"
                            key={index}
                          >
                            <div className="task_heading_column">
                              <div className="task_name_column">
                                {item.sub_tasks.length !== 0 ? (
                                  <span
                                    className="collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapase${item.id}`}
                                    aria-expanded="false"
                                    aria-controls={`collapase${item.id}`}
                                  >
                                    <i className="uil-arrow-down" />
                                  </span>
                                ) : (
                                  <span style={{ paddingRight: "14px" }} />
                                )}
                                <input
                                  className="form-check-input"
                                  onChange={(e) => {
                                    setComplete(e, item);
                                  }}
                                  type="checkbox"
                                  style={{ borderRadius: "100px" }}
                                  name="formRadios"
                                  id={`formRadios${item.id}`}
                                  checked={item.status === "complete"}
                                />
                                <div className="form-check mt-4 w-100 h-100">
                                  <Link
                                    to="#"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target={`#offcanvasRight2`}
                                    aria-controls={`offcanvasRight2`}
                                    onClick={(e) =>
                                      handelEditDrawer(e, item.id)
                                    }
                                  >
                                    {item.status === "complete" ? (
                                      <del className="StrikedThrouhgh">
                                        <p
                                          className="form-check-label mx-50 m-0 shink"
                                          style={{
                                            color: "#404e67",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {item.title}
                                        </p>
                                      </del>
                                    ) : (
                                      <p
                                        className="form-check-label mx-50 m-0 shink"
                                        style={{
                                          color: "#404e67",

                                          cursor: "pointer",
                                        }}
                                      >
                                        {item.title}
                                      </p>
                                    )}
                                  </Link>
                                </div>
                              </div>
                              <div className="Projects_name_column">
                                {userPermissions.indexOf("tasks-edit") !==
                                -1 ? (
                                  <LeadPendingField
                                    item={item}
                                    Status={taskStatus}
                                    DefaultStatus={item.status}
                                    Services={TaskServices.updateTaskStatus}
                                    data={(e) => reSetData(e)}
                                  />
                                ) : (
                                  <span>{item.status}</span>
                                )}
                              </div>
                              <div className="Projects_name_column ">
                                {userPermissions.indexOf("tasks-edit") !==
                                -1 ? (
                                  <AssigneField
                                    item={item}
                                    assigne={taskOwner}
                                    Services={TaskServices.updateTaskAssigne}
                                    Owner={item.task_assigned_to}
                                    newOwner={item.asigned_to}
                                    data={(e) => reSetData(e)}
                                    reFreshData={(e) => reSetData(e)}
                                  />
                                ) : (
                                  <span>
                                    {item.task_assigned_to.first_name}{" "}
                                    {item.task_assigned_to.last_name}
                                  </span>
                                )}
                              </div>
                              <div className="Projects_name_column">
                                {item.project_name}
                              </div>
                              <div className="date_name_column">
                                {dateLoader ? (
                                  <>
                                    <Skeleton />
                                  </>
                                ) : (
                                  <>
                                    <p>
                                      <DateField
                                        item={item}
                                        defaultDate={item.due_date}
                                        Services={
                                          TaskServices.updateTaskDueDate
                                        }
                                        data={(e) => reSetData(e)}
                                        styles={{ width: "100%" }}
                                      />
                                    </p>
                                  </>
                                )}
                              </div>
                            </div>
                            <div
                              id={`collapase${item.id}`}
                              className="accordion-collapse collapse"
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              {item &&
                                item.sub_tasks?.map((el, index) => (
                                  <div className="task_heading_column">
                                    <div className="task_name_column">
                                      <span style={{ paddingRight: "35px" }} />

                                      <input
                                        className="form-check-input"
                                        onChange={(e) => {
                                          setComplete(e, el);
                                        }}
                                        type="checkbox"
                                        style={{ borderRadius: "100px" }}
                                        name="formRadios"
                                        id={`formRadios${el.id}`}
                                        checked={el.status === "complete"}
                                      />
                                      <div className="form-check mt-4 w-100 h-100">
                                        <Link
                                          to="#"
                                          data-bs-toggle="offcanvas"
                                          data-bs-target={`#offcanvasRight2`}
                                          aria-controls={`offcanvasRight2`}
                                          onClick={(e) =>
                                            handelEditDrawer(e, el.id)
                                          }
                                        >
                                          {el.status === "complete" ? (
                                            <del className="StrikedThrouhgh">
                                              <p
                                                className="form-check-label mx-50 m-0 shink"
                                                style={{
                                                  color: "#404e67",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {el.title}
                                              </p>
                                            </del>
                                          ) : (
                                            <p
                                              className="form-check-label mx-50 m-0 shink"
                                              style={{
                                                color: "#404e67",

                                                cursor: "pointer",
                                              }}
                                            >
                                              {el.title}
                                            </p>
                                          )}
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="Projects_name_column">
                                      {userPermissions.indexOf("tasks-edit") !==
                                      -1 ? (
                                        <LeadPendingField
                                          item={el}
                                          Status={taskStatus}
                                          DefaultStatus={el.status}
                                          Services={
                                            TaskServices.updateTaskStatus
                                          }
                                          data={(e) => reSetData(e)}
                                        />
                                      ) : (
                                        <span>{el.status}</span>
                                      )}
                                    </div>
                                    <div className="Projects_name_column ">
                                      {userPermissions.indexOf("tasks-edit") !==
                                      -1 ? (
                                        <>
                                          <AssigneField
                                            item={el}
                                            assigne={taskOwner}
                                            Services={
                                              TaskServices.updateTaskAssigne
                                            }
                                            Owner={el.asignedd_to}
                                            newOwner={el.asigned_to}
                                            data={(e) => reSetData(e)}
                                            reFreshData={(e) => reSetData(e)}
                                          />
                                        </>
                                      ) : (
                                        <span>
                                          {el.assigned_to.first_name}{" "}
                                          {el.assigned_to.last_name}
                                        </span>
                                      )}
                                    </div>
                                    <div className="Projects_name_column">
                                      {el.project_name}
                                    </div>
                                    <div className="date_name_column">
                                      {dateLoader ? (
                                        <>
                                          <Skeleton />
                                        </>
                                      ) : (
                                        <>
                                          <p>
                                            <DateField
                                              item={el}
                                              defaultDate={el.due_date}
                                              Services={
                                                TaskServices.updateTaskDueDate
                                              }
                                              data={(e) => reSetData(e)}
                                              styles={{ width: "100%" }}
                                            />
                                          </p>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </Fade>
                  )}

                  {allFilter(tasksAssignetToME && tasksAssignetToME)?.filter(
                    (el) =>
                      moment(el.due_date).format("YYYY-MM-DD") >
                        moment().format("YYYY-MM-DD") &&
                      moment(el.due_date).format("YYYY-MM-DD") <=
                        moment().add(7, "days").format("YYYY-MM-DD")
                  ).length === 0 ? null : (
                    <Fade big>
                      <h2 className="added_by_me" id="headingOne">
                        <span
                          className=""
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapaseTwo"
                          aria-expanded="true"
                          aria-controls="collapaseTwo"
                        >
                          <i className="uil-arrow-down" /> Due in 7 days
                        </span>
                      </h2>
                      <div
                        id="collapaseTwo"
                        className="accordion-collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        {allFilter(
                          tasksAssignetToME &&
                            tasksAssignetToME.filter(
                              (el) =>
                                moment(el.due_date).format("YYYY-MM-DD") >
                                  moment().format("YYYY-MM-DD") &&
                                moment(el.due_date).format("YYYY-MM-DD") <=
                                  moment().add(7, "days").format("YYYY-MM-DD")
                            )
                        )?.map((item, index) => (
                          <div
                            className="todo-task-list list-group ps w-100"
                            key={index}
                          >
                            <div className="task_heading_column">
                              <div className="task_name_column">
                                {item.sub_tasks.length !== 0 ? (
                                  <span
                                    className="collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapase${item.id}`}
                                    aria-expanded="false"
                                    aria-controls={`collapase${item.id}`}
                                  >
                                    <i className="uil-arrow-down" />
                                  </span>
                                ) : (
                                  <span style={{ paddingRight: "14px" }} />
                                )}
                                <input
                                  className="form-check-input"
                                  onChange={(e) => {
                                    setComplete(e, item);
                                  }}
                                  type="checkbox"
                                  style={{ borderRadius: "100px" }}
                                  name="formRadios"
                                  id={`formRadios${item.id}`}
                                  checked={item.status === "complete"}
                                />
                                <div className="form-check mt-4 w-100 h-100">
                                  <Link
                                    to="#"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target={`#offcanvasRight2`}
                                    aria-controls={`offcanvasRight2`}
                                    onClick={(e) =>
                                      handelEditDrawer(e, item.id)
                                    }
                                  >
                                    {item.status === "complete" ? (
                                      <del className="StrikedThrouhgh">
                                        <p
                                          className="form-check-label mx-50 m-0 shink"
                                          style={{
                                            color: "#404e67",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {item.title}
                                        </p>
                                      </del>
                                    ) : (
                                      <p
                                        className="form-check-label mx-50 m-0 shink"
                                        style={{
                                          color: "#404e67",

                                          cursor: "pointer",
                                        }}
                                      >
                                        {item.title}
                                      </p>
                                    )}
                                  </Link>
                                </div>
                              </div>
                              <div className="Projects_name_column">
                                {userPermissions.indexOf("tasks-edit") !==
                                -1 ? (
                                  <LeadPendingField
                                    item={item}
                                    Status={taskStatus}
                                    DefaultStatus={item.status}
                                    Services={TaskServices.updateTaskStatus}
                                    data={(e) => reSetData(e)}
                                  />
                                ) : (
                                  <span>{item.status}</span>
                                )}
                              </div>
                              <div className="Projects_name_column ">
                                {userPermissions.indexOf("tasks-edit") !==
                                -1 ? (
                                  <AssigneField
                                    item={item}
                                    assigne={taskOwner}
                                    Services={TaskServices.updateTaskAssigne}
                                    Owner={item.task_assigned_to}
                                    newOwner={item.asigned_to}
                                    data={(e) => reSetData(e)}
                                    reFreshData={(e) => reSetData(e)}
                                  />
                                ) : (
                                  <span>
                                    {item.task_assigned_to.first_name}{" "}
                                    {item.task_assigned_to.last_name}
                                  </span>
                                )}
                              </div>
                              <div className="Projects_name_column">
                                {item.project_name}
                              </div>
                              <div className="date_name_column">
                                {dateLoader ? (
                                  <>
                                    <Skeleton />
                                  </>
                                ) : (
                                  <>
                                    <p>
                                      <DateField
                                        item={item}
                                        defaultDate={item.due_date}
                                        Services={
                                          TaskServices.updateTaskDueDate
                                        }
                                        data={(e) => reSetData(e)}
                                        styles={{ width: "100%" }}
                                      />
                                    </p>
                                  </>
                                )}
                              </div>
                            </div>
                            <div
                              id={`collapase${item.id}`}
                              className="accordion-collapse collapse"
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              {item &&
                                item.sub_tasks?.map((el, index) => (
                                  <div className="task_heading_column">
                                    <div className="task_name_column">
                                      <span style={{ paddingRight: "35px" }} />

                                      <input
                                        className="form-check-input"
                                        onChange={(e) => {
                                          setComplete(e, el);
                                        }}
                                        type="checkbox"
                                        style={{ borderRadius: "100px" }}
                                        name="formRadios"
                                        id={`formRadios${el.id}`}
                                        checked={el.status === "complete"}
                                      />
                                      <div className="form-check mt-4 w-100 h-100">
                                        <Link
                                          to="#"
                                          data-bs-toggle="offcanvas"
                                          data-bs-target={`#offcanvasRight2`}
                                          aria-controls={`offcanvasRight2`}
                                          onClick={(e) =>
                                            handelEditDrawer(e, el.id)
                                          }
                                        >
                                          {el.status === "complete" ? (
                                            <del className="StrikedThrouhgh">
                                              <p
                                                className="form-check-label mx-50 m-0 shink"
                                                style={{
                                                  color: "#404e67",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {el.title}
                                              </p>
                                            </del>
                                          ) : (
                                            <p
                                              className="form-check-label mx-50 m-0 shink"
                                              style={{
                                                color: "#404e67",

                                                cursor: "pointer",
                                              }}
                                            >
                                              {el.title}
                                            </p>
                                          )}
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="Projects_name_column">
                                      {userPermissions.indexOf("tasks-edit") !==
                                      -1 ? (
                                        <LeadPendingField
                                          item={el}
                                          Status={taskStatus}
                                          DefaultStatus={el.status}
                                          Services={
                                            TaskServices.updateTaskStatus
                                          }
                                          data={(e) => reSetData(e)}
                                        />
                                      ) : (
                                        <span>{el.status}</span>
                                      )}
                                    </div>
                                    <div className="Projects_name_column ">
                                      {userPermissions.indexOf("tasks-edit") !==
                                      -1 ? (
                                        <AssigneField
                                          item={el}
                                          assigne={taskOwner}
                                          Services={
                                            TaskServices.updateTaskAssigne
                                          }
                                          Owner={el.asignedd_to}
                                          newOwner={el.asigned_to}
                                          data={(e) => reSetData(e)}
                                          reFreshData={(e) => reSetData(e)}
                                        />
                                      ) : (
                                        <span>
                                          {el.assigned_to.first_name}{" "}
                                          {el.assigned_to.last_name}
                                        </span>
                                      )}
                                    </div>
                                    <div className="Projects_name_column">
                                      {el.project_name}
                                    </div>
                                    <div className="date_name_column">
                                      {dateLoader ? (
                                        <>
                                          <Skeleton />
                                        </>
                                      ) : (
                                        <>
                                          <p>
                                            <DateField
                                              item={el}
                                              defaultDate={el.due_date}
                                              Services={
                                                TaskServices.updateTaskDueDate
                                              }
                                              data={(e) => reSetData(e)}
                                              styles={{ width: "100%" }}
                                            />
                                          </p>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </Fade>
                  )}

                  {allFilter(tasksAssignetToME && tasksAssignetToME)?.filter(
                    (el) =>
                      moment(el.due_date).format("YYYY-MM-DD") >=
                      moment().add(8, "days").format("YYYY-MM-DD")
                  ).length === 0 ? null : (
                    <Fade big>
                      <h2 className="added_by_me" id="headingOne">
                        <span
                          className=""
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapaseThree"
                          aria-expanded="true"
                          aria-controls="collapaseThree"
                        >
                          <i className="uil-arrow-down" />
                          Other / Delayed
                        </span>
                      </h2>
                      <div
                        id="collapaseThree"
                        className="accordion-collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        {allFilter(
                          tasksAssignetToME &&
                            tasksAssignetToME?.filter(
                              (el) =>
                                moment(el.due_date).format("YYYY-MM-DD") >=
                                moment().add(8, "days").format("YYYY-MM-DD")
                            )
                        )?.map((item, index) => (
                          <div
                            className="todo-task-list list-group ps w-100"
                            key={index}
                          >
                            <div className="task_heading_column">
                              <div className="task_name_column">
                                {item.sub_tasks.length !== 0 ? (
                                  <span
                                    className="collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapase${item.id}`}
                                    aria-expanded="false"
                                    aria-controls={`collapase${item.id}`}
                                  >
                                    <i className="uil-arrow-down" />
                                  </span>
                                ) : (
                                  <span style={{ paddingRight: "14px" }} />
                                )}
                                <input
                                  className="form-check-input"
                                  onChange={(e) => {
                                    setComplete(e, item);
                                  }}
                                  type="checkbox"
                                  style={{ borderRadius: "100px" }}
                                  name="formRadios"
                                  id={`formRadios${item.id}`}
                                  checked={item.status === "complete"}
                                />
                                <div className="form-check mt-4 w-100 h-100">
                                  <Link
                                    to="#"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target={`#offcanvasRight2`}
                                    aria-controls={`offcanvasRight2`}
                                    onClick={(e) =>
                                      handelEditDrawer(e, item.id)
                                    }
                                  >
                                    {item.status === "complete" ? (
                                      <del className="StrikedThrouhgh">
                                        <p
                                          className="form-check-label mx-50 m-0 shink"
                                          style={{
                                            color: "#404e67",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {item.title}
                                        </p>
                                      </del>
                                    ) : (
                                      <p
                                        className="form-check-label mx-50 m-0 shink"
                                        style={{
                                          color: "#404e67",

                                          cursor: "pointer",
                                        }}
                                      >
                                        {item.title}
                                      </p>
                                    )}
                                  </Link>
                                </div>
                              </div>
                              <div className="Projects_name_column">
                                {userPermissions.indexOf("tasks-edit") !==
                                -1 ? (
                                  <LeadPendingField
                                    item={item}
                                    Status={taskStatus}
                                    DefaultStatus={item.status}
                                    Services={TaskServices.updateTaskStatus}
                                    data={(e) => reSetData(e)}
                                  />
                                ) : (
                                  <span>{item.status}</span>
                                )}
                              </div>
                              <div className="Projects_name_column ">
                                {userPermissions.indexOf("tasks-edit") !==
                                -1 ? (
                                  <AssigneField
                                    item={item}
                                    assigne={taskOwner}
                                    Services={TaskServices.updateTaskAssigne}
                                    Owner={item.task_assigned_to}
                                    newOwner={item.asigned_to}
                                    data={(e) => reSetData(e)}
                                    reFreshData={(e) => reSetData(e)}
                                  />
                                ) : (
                                  <span>
                                    {item.task_assigned_to.first_name}{" "}
                                    {item.task_assigned_to.last_name}
                                  </span>
                                )}
                              </div>
                              <div className="Projects_name_column">
                                {item.project_name}
                              </div>
                              <div className="date_name_column">
                                {dateLoader ? (
                                  <>
                                    <Skeleton />
                                  </>
                                ) : (
                                  <>
                                    <p>
                                      <DateField
                                        item={item}
                                        defaultDate={item.due_date}
                                        Services={
                                          TaskServices.updateTaskDueDate
                                        }
                                        data={(e) => reSetData(e)}
                                        styles={{ width: "100%" }}
                                      />
                                    </p>
                                  </>
                                )}
                              </div>
                            </div>
                            <div
                              id={`collapase${item.id}`}
                              className="accordion-collapse collapse"
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              {item &&
                                item.sub_tasks?.map((el, index) => (
                                  <div className="task_heading_column">
                                    <div className="task_name_column">
                                      <span style={{ paddingRight: "35px" }} />

                                      <input
                                        className="form-check-input"
                                        onChange={(e) => {
                                          setComplete(e, el);
                                        }}
                                        type="checkbox"
                                        style={{ borderRadius: "100px" }}
                                        name="formRadios"
                                        id={`formRadios${el.id}`}
                                        checked={el.status === "complete"}
                                      />
                                      <div className="form-check mt-4 w-100 h-100">
                                        <Link
                                          to="#"
                                          data-bs-toggle="offcanvas"
                                          data-bs-target={`#offcanvasRight2`}
                                          aria-controls={`offcanvasRight2`}
                                          onClick={(e) =>
                                            handelEditDrawer(e, el.id)
                                          }
                                        >
                                          {el.status === "complete" ? (
                                            <del className="StrikedThrouhgh">
                                              <p
                                                className="form-check-label mx-50 m-0 shink"
                                                style={{
                                                  color: "#404e67",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {el.title}
                                              </p>
                                            </del>
                                          ) : (
                                            <p
                                              className="form-check-label mx-50 m-0 shink"
                                              style={{
                                                color: "#404e67",

                                                cursor: "pointer",
                                              }}
                                            >
                                              {el.title}
                                            </p>
                                          )}
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="Projects_name_column">
                                      {userPermissions.indexOf("tasks-edit") !==
                                      -1 ? (
                                        <LeadPendingField
                                          item={el}
                                          Status={taskStatus}
                                          DefaultStatus={el.status}
                                          Services={
                                            TaskServices.updateTaskStatus
                                          }
                                          data={(e) => reSetData(e)}
                                        />
                                      ) : (
                                        <span>{el.status}</span>
                                      )}
                                    </div>
                                    <div className="Projects_name_column ">
                                      {userPermissions.indexOf("tasks-edit") !==
                                      -1 ? (
                                        <AssigneField
                                          item={el}
                                          assigne={taskOwner}
                                          Services={
                                            TaskServices.updateTaskAssigne
                                          }
                                          Owner={el.asignedd_to}
                                          newOwner={el.asigned_to}
                                          data={(e) => reSetData(e)}
                                          reFreshData={(e) => reSetData(e)}
                                        />
                                      ) : (
                                        <span>
                                          {el.assigned_to.first_name}{" "}
                                          {el.assigned_to.last_name}
                                        </span>
                                      )}
                                    </div>
                                    <div className="Projects_name_column">
                                      {el.project_name}
                                    </div>
                                    <div className="date_name_column">
                                      {dateLoader ? (
                                        <>
                                          <Skeleton />
                                        </>
                                      ) : (
                                        <>
                                          <p>
                                            <DateField
                                              item={el}
                                              defaultDate={el.due_date}
                                              Services={
                                                TaskServices.updateTaskDueDate
                                              }
                                              data={(e) => reSetData(e)}
                                              styles={{ width: "100%" }}
                                            />
                                          </p>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </Fade>
                  )}

                  {tasksAssignetByME?.length !== 0 ? (
                    <Fade big>
                      <h2 className="added_by_me" id="headingOne">
                        <span
                          className=""
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOneMe"
                          aria-expanded="true"
                          aria-controls="collapseOneMe"
                        >
                          <i className="uil-arrow-down" /> Tasks Assigned by Me
                        </span>
                      </h2>
                      <div
                        id="collapseOneMe"
                        className="accordion-collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        {isLoading ? (
                          <>
                            <div className="todo-task-list list-group ps w-100">
                              <div className="task_heading_column">
                                <div className="task_name_column">
                                  <div className="form-check mt-4 w-100 h-100">
                                    <Skeleton width="50%" />
                                  </div>
                                </div>
                                <div className="Projects_name_column">
                                  <Skeleton style={{ width: "90%" }} />
                                </div>
                                <div className="Projects_name_column ">
                                  <Skeleton style={{ width: "90%" }} />
                                </div>
                                <div className="Projects_name_column">
                                  <Skeleton style={{ width: "90%" }} />
                                </div>
                                <div className="date_name_column">
                                  <Skeleton style={{ width: "90%" }} />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {allFilter(
                              tasksAssignetByME && tasksAssignetByME
                            )?.map((item, index) => (
                              <div
                                className="todo-task-list list-group ps w-100"
                                key={index}
                              >
                                <div className="task_heading_column">
                                  <div className="task_name_column">
                                    {item.sub_tasks.length !== 0 ? (
                                      <span
                                        className="collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapase${item.id}`}
                                        aria-expanded="false"
                                        aria-controls={`collapase${item.id}`}
                                      >
                                        <i className="uil-arrow-down" />
                                      </span>
                                    ) : (
                                      <span style={{ paddingRight: "14px" }} />
                                    )}
                                    <input
                                      className="form-check-input"
                                      onChange={(e) => {
                                        setComplete(e, item);
                                      }}
                                      type="checkbox"
                                      style={{ borderRadius: "100px" }}
                                      name="formRadios"
                                      id={`formRadios${item.id}`}
                                      checked={item.status === "complete"}
                                    />
                                    <div className="form-check mt-4 w-100 h-100">
                                      <Link
                                        to="#"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target={`#offcanvasRight2`}
                                        aria-controls={`offcanvasRight2`}
                                        onClick={(e) =>
                                          handelEditDrawer(e, item.id)
                                        }
                                      >
                                        {item.status === "complete" ? (
                                          <del className="StrikedThrouhgh">
                                            <p
                                              className="form-check-label mx-50 m-0 shink"
                                              style={{
                                                color: "#404e67",
                                                cursor: "pointer",
                                              }}
                                            >
                                              {item.title}
                                            </p>
                                          </del>
                                        ) : (
                                          <p
                                            className="form-check-label mx-50 m-0 shink"
                                            style={{
                                              color: "#404e67",

                                              cursor: "pointer",
                                            }}
                                          >
                                            {item.title}
                                          </p>
                                        )}
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="Projects_name_column">
                                    {userPermissions.indexOf("tasks-edit") !==
                                    -1 ? (
                                      <LeadPendingField
                                        item={item}
                                        Status={taskStatus}
                                        DefaultStatus={item.status}
                                        Services={TaskServices.updateTaskStatus}
                                        data={(e) => reSetData(e)}
                                      />
                                    ) : (
                                      <span>{item.status}</span>
                                    )}
                                  </div>
                                  <div className="Projects_name_column ">
                                    {userPermissions.indexOf("tasks-edit") !==
                                    -1 ? (
                                      <AssigneField
                                        item={item}
                                        assigne={taskOwner}
                                        Services={
                                          TaskServices.updateTaskAssigne
                                        }
                                        Owner={item.task_assigned_to}
                                        newOwner={item.asigned_to}
                                        data={(e) => reSetData(e)}
                                        reFreshData={(e) => reSetData(e)}
                                      />
                                    ) : (
                                      <span>
                                        {item.task_assigned_to.first_name}{" "}
                                        {item.task_assigned_to.last_name}
                                      </span>
                                    )}
                                  </div>
                                  <div className="Projects_name_column">
                                    {item.project_name}
                                  </div>
                                  <div className="date_name_column">
                                    {dateLoader ? (
                                      <>
                                        <Skeleton />
                                      </>
                                    ) : (
                                      <>
                                        <p>
                                          <DateField
                                            item={item}
                                            defaultDate={item.due_date}
                                            Services={
                                              TaskServices.updateTaskDueDate
                                            }
                                            data={(e) => reSetData(e)}
                                            styles={{ width: "100%" }}
                                          />
                                        </p>
                                      </>
                                    )}
                                  </div>
                                </div>
                                <div
                                  id={`collapase${item.id}`}
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingOne"
                                  data-bs-parent="#accordionExample"
                                >
                                  {item &&
                                    item.sub_tasks?.map((el, index) => (
                                      <div className="task_heading_column">
                                        <div className="task_name_column">
                                          <span
                                            style={{ paddingRight: "35px" }}
                                          />

                                          <input
                                            className="form-check-input"
                                            onChange={(e) => {
                                              setComplete(e, el);
                                            }}
                                            type="checkbox"
                                            style={{ borderRadius: "100px" }}
                                            name="formRadios"
                                            id={`formRadios${el.id}`}
                                            checked={el.status === "complete"}
                                          />
                                          <div className="form-check mt-4 w-100 h-100">
                                            <Link
                                              to="#"
                                              data-bs-toggle="offcanvas"
                                              data-bs-target={`#offcanvasRight2`}
                                              aria-controls={`offcanvasRight2`}
                                              onClick={(e) =>
                                                handelEditDrawer(e, el.id)
                                              }
                                            >
                                              {el.status === "complete" ? (
                                                <del className="StrikedThrouhgh">
                                                  <p
                                                    className="form-check-label mx-50 m-0 shink"
                                                    style={{
                                                      color: "#404e67",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {el.title}
                                                  </p>
                                                </del>
                                              ) : (
                                                <p
                                                  className="form-check-label mx-50 m-0 shink"
                                                  style={{
                                                    color: "#404e67",

                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {el.title}
                                                </p>
                                              )}
                                            </Link>
                                          </div>
                                        </div>
                                        <div className="Projects_name_column">
                                          {userPermissions.indexOf(
                                            "tasks-edit"
                                          ) !== -1 ? (
                                            <LeadPendingField
                                              item={el}
                                              Status={taskStatus}
                                              DefaultStatus={el.status}
                                              Services={
                                                TaskServices.updateTaskStatus
                                              }
                                              data={(e) => reSetData(e)}
                                            />
                                          ) : (
                                            <span>{el.status}</span>
                                          )}
                                        </div>
                                        <div className="Projects_name_column ">
                                          {userPermissions.indexOf(
                                            "tasks-edit"
                                          ) !== -1 ? (
                                            <AssigneField
                                              item={el}
                                              assigne={taskOwner}
                                              Services={
                                                TaskServices.updateTaskAssigne
                                              }
                                              Owner={el.asignedd_to}
                                              newOwner={el.asigned_to}
                                              data={(e) => reSetData(e)}
                                              reFreshData={(e) => reSetData(e)}
                                            />
                                          ) : (
                                            <span>
                                              {el.assigned_to.first_name}{" "}
                                              {el.assigned_to.last_name}
                                            </span>
                                          )}
                                        </div>
                                        <div className="Projects_name_column">
                                          {el.project_name}
                                        </div>
                                        <div className="date_name_column">
                                          {dateLoader ? (
                                            <>
                                              <Skeleton />
                                            </>
                                          ) : (
                                            <>
                                              <p>
                                                <DateField
                                                  item={el}
                                                  defaultDate={el.due_date}
                                                  Services={
                                                    TaskServices.updateTaskDueDate
                                                  }
                                                  data={(e) => reSetData(e)}
                                                  styles={{ width: "100%" }}
                                                />
                                              </p>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </Fade>
                  ) : null}
                </>
              </Fade>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Tasks;
