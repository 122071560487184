import React, { useState, useEffect } from "react";
import shortid from "shortid";
import { DatePicker } from "antd";
import moment from "moment";
import DataParser from "../../../utlits/Dataparser";
import PhyicalYearService from "../../../Services/PhyicalYearService";
import LeaveApplicationService from "../../../Services/LeaveApplicationService";

const AddLeaves = (props) => {
  const { id, labelledby, leavePolicyList } = props;
  const [leaveReason, setLeaveReason] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [numberOfDays, setNumberOfDays] = useState("");
  const [consecutiveAllowed, setConsecutiveAllowed] = useState("");
  const [leavesDates, setLeavesDates] = useState([]);
  const [sessionId, setSessionId] = useState("");

  const companyId = DataParser.CompanyId();
  const userId = DataParser.UserId();

  const onDateChangeFrom = (date) => {
    setFromDate(date);
  };
  const onDateChangeTo = (date) => {
    setToDate(date);
  };

  const getPhysicalPeriodDetails = () => {
    PhyicalYearService.getPhysicalYearDetails().then((res) => {
      const data = res.data;
      setSessionId(data.id);
    });
  };

  useEffect(() => {
    const consetive = leavePolicyList.filter(
      (es) => es.leave_type_id == leaveType
    );
    setConsecutiveAllowed(consetive[0]?.consecutive_allowed);
  }, [leaveType]);

  useEffect(() => {
    const datee = DataParser.getDateinFormat();
    setFromDate(datee);
    setToDate(datee);
    getPhysicalPeriodDetails();
  }, []);

  useEffect(() => {
    // start time and end time
    var startTime = moment(fromDate, "YYYY, MM, DD");
    var endTime = moment(toDate, "YYYY, MM, DD");
    const days = endTime.diff(startTime, "days");
    enumerateDaysBetweenDates(startTime, endTime);
  }, [toDate]);

  const enumerateDaysBetweenDates = function (startDate, endDate) {
    var dates = [];

    var currDate = moment(startDate).startOf("day");
    var lastDate = moment(endDate).startOf("day");
    dates.push(moment(currDate).format("DD/MM/YYYY"));
    while (currDate.add(1, "days").diff(lastDate) < 0) {
      const data = currDate.clone().toDate();
      dates.push(moment(data).format("DD/MM/YYYY"));
    }
    if (
      moment(startDate).format("DD/MM/YYYY") !==
      moment(endDate).format("DD/MM/YYYY")
    ) {
      dates.push(moment(lastDate).format("DD/MM/YYYY"));
    }
    setLeavesDates(dates);
    setNumberOfDays(dates.length);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      company_id: companyId,
      reason: leaveReason,
      empid: userId,
      from_date: fromDate,
      to_date: toDate,
      totalleaves: numberOfDays,
      typ: leaveType,
      leavesession: sessionId,
      leavedates: leavesDates,
    };

    const link = document.getElementById("closeLeave");
    LeaveApplicationService.applyLeave(body).then((res) => {
      console.log("applyLeave data: ", res);
      link.click();
    });
  };

  const disabledEndDate = (current) => {
    // Disable all dates before the selected start date plus 3 days
    return (
      current &&
      !(
        current >= moment(fromDate) &&
        current <= moment(fromDate).add(consecutiveAllowed - 1, "days")
      )
    );
  };

  return (
    <div
      className="offcanvas offcanvas-end"
      tabIndex={-1}
      id={id}
      aria-labelledby={labelledby}
      style={{ width: "600px" }}
    >
      <div className="offcanvas-header border-bottom">
        <h5 id={labelledby}>Leaves</h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          id="closeLeave"
        />
      </div>
      <div className="offcanvas-body">
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="card-content tasks_custom_styling add_task_input_fields mt-1 overxy">
            <div className="row" style={{ paddingRight: "2rem" }}>
              <div className="col-12 col-sm-12">
                <fieldset className="form-group row mt-2">
                  <div className="col-sm-3 pl-2">
                    <label className="offcanvas_labels" htmlFor="basicSelect">
                      Leave Type<span className="alert_star">*</span>
                    </label>
                  </div>
                  <div className="col-sm-9">
                    <select
                      className="form-control selectset custominput"
                      style={{ border: "0px" }}
                      name="lead_owner"
                      id="basicSelect"
                      required
                      value={leaveType}
                      onChange={(e) => setLeaveType(e.target.value)}
                    >
                      <option value="">Please Select</option>
                      {leavePolicyList &&
                        leavePolicyList.map((el) => (
                          <option
                            key={shortid.generate()}
                            value={el.leave_type_id}
                          >
                            {el.leave_type_name}
                          </option>
                        ))}
                    </select>
                  </div>
                </fieldset>
              </div>
              <div className="col-12 col-sm-12">
                <fieldset className="form-group row mt-2">
                  <div className="col-sm-3 pl-2">
                    <label className="offcanvas_labels" htmlFor="basicSelect1">
                      From<span className="alert_star">*</span>
                    </label>
                  </div>
                  <div className="col-sm-9">
                    <DatePicker
                      id="basicSelect1"
                      disabledDate={(current) => {
                        let customDate = moment().format("YYYY-MM-DD");
                        return (
                          current && current < moment(customDate, "YYYY-MM-DD")
                        );
                      }}
                      allowClear={false}
                      bordered={false}
                      value={moment(fromDate)}
                      onChange={onDateChangeFrom}
                      format={"DD-MM-YYYY"}
                    />
                  </div>
                </fieldset>
              </div>
              <div className="col-12 col-sm-12">
                <fieldset className="form-group row mt-2">
                  <div className="col-sm-3 pl-2">
                    <label className="offcanvas_labels" htmlFor="basicSelect2">
                      To<span className="alert_star">*</span>
                    </label>
                  </div>
                  <div className="col-sm-9">
                    <DatePicker
                      id="basicSelect2"
                      allowClear={false}
                      bordered={false}
                      value={moment(toDate)}
                      onChange={onDateChangeTo}
                      format={"DD-MM-YYYY"}
                      disabledDate={disabledEndDate}
                    />
                  </div>
                </fieldset>
              </div>
              <div className="col-12 col-sm-12">
                <fieldset className="form-group row mt-2">
                  <div className="col-sm-3 pl-2">
                    <label className="offcanvas_labels" htmlFor="basicSelect3">
                      Number of days<span className="alert_star">*</span>
                    </label>
                  </div>
                  <div className="col-sm-9">
                    <input
                      id="basicSelect3"
                      type="text"
                      className="form-control mb-3"
                      required
                      disabled
                      value={numberOfDays}
                      onChange={(e) => setNumberOfDays(e.target.value)}
                    />
                  </div>
                </fieldset>
              </div>
              <div className="col-12 col-sm-12">
                <fieldset className="form-group row mt-2">
                  <div className="col-sm-3 pl-2">
                    <label className="offcanvas_labels" htmlFor="basicSelect4">
                      Leave Reason<span className="alert_star">*</span>
                    </label>
                  </div>
                  <div className="col-sm-9">
                    <textarea
                      required
                      className="form-control w-100 autosize textset custominput2"
                      rows={3}
                      placeholder="Details"
                      id="basicSelect4"
                      name="details"
                      data-validation-required-message="This role field is required"
                      data-gramm="false"
                      wt-ignore-input="true"
                      value={leaveReason}
                      onChange={(e) => setLeaveReason(e.target.value)}
                    />
                  </div>
                </fieldset>
              </div>
              <div className="col-12 d-flex flex-sm-row flex-column justify-content-end mt-4">
                <button
                  type="submit"
                  // onClick={(e)  => handleSubmit(e)}
                  className="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddLeaves;
