import React from "react";
import { Link } from "react-router-dom";
import DataParser from "../../../utlits/Dataparser";
import Sidebar from "../../Layouts/Sidebar";

const HRSettings = () => {
  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <h3 className="text mb-5">HR Settings</h3>
            </div>
          </div>
          <div className="row">
            
            <div className="col-md-6 col-xl-3">
              <Link
                to={`/${DataParser.CompanyIdentifierRole()}/leave_entitlement`}
              >
                <div className="card">
                  <div className="card-body setting_tabs">
                    <i
                      style={{ color: "#000" }}
                      className="uil-process setting_tabs_icon"
                    />

                    <h4 className="mb-1 mt-1 setting_tabs_text">
                      Leave Entitlement
                    </h4>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-xl-3">
              <Link to={`/${DataParser.CompanyIdentifierRole()}/leave_type`}>
                <div className="card">
                  <div className="card-body setting_tabs">
                    <i
                      style={{ color: "#000" }}
                      className="uil-notebooks setting_tabs_icon"
                    />

                    <h4 className="mb-1 mt-1 setting_tabs_text">Leave Type</h4>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-xl-3">
              <Link to={`/${DataParser.CompanyIdentifierRole()}/leave_period`}>
                <div className="card">
                  <div className="card-body setting_tabs">
                    <i
                      style={{ color: "#000" }}
                      className="uil-history-alt setting_tabs_icon"
                    />

                    <h4 className="mb-1 mt-1 setting_tabs_text">
                      Leave Seasions
                    </h4>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-xl-3">
              <Link to={`/${DataParser.CompanyIdentifierRole()}/holidays`}>
                <div className="card">
                  <div className="card-body setting_tabs">
                    <i
                      style={{ color: "#000" }}
                      className="uil-megaphone setting_tabs_icon"
                    />

                    <h4 className="mb-1 mt-1 setting_tabs_text">Holidays</h4>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HRSettings;
