import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import moment from "moment/moment";
import Sidebar from "../../Layouts/Sidebar";
import PhyicalYearService from "../../../Services/PhyicalYearService";
import DataParser from "../../../utlits/Dataparser";

const AddPhysicalPeriod = () => {
  const dateFormat = "YYYY/MM/DD";
  const companyid = DataParser.CompanyId();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [updateId, setUpdateId] = useState(null);

  const onChange = (date, dateString) => {
    let startDate = moment(date);
    let endDates = moment(startDate, dateFormat).add(364, "days");
    setStartDate(startDate);
    setEndDate(endDates);
    let dataBody = {
      company_id: companyid,
      start_date: startDate,
      end_date: endDates,
    };
    PhyicalYearService.updatePhysicalYear(updateId, dataBody).then((res) => {});
  };

  const getPhysicalPeriodDetails = (c) => {
    PhyicalYearService.getPhysicalYearDetails().then((res) => {
      const data = res.data;
      setUpdateId(data.id);
      setStartDate(moment(data.start_date));
      setEndDate(moment(data.end_date));
    });
  };

  useEffect(() => {
    getPhysicalPeriodDetails();
  }, []);

  useEffect(() => {
    const companyid = DataParser.CompanyId();

    if (startDate !== "" && endDate !== "") {
      if (moment() > endDate) {
        let dataBody = {
          company_id: companyid,
          start_date: moment(startDate).add(1, "years"),
          end_date: moment(endDate).add(1, "years"),
        };
        PhyicalYearService.createPhysicalYear(dataBody).then((res) => {
          getPhysicalPeriodDetails();
        });
      }
    }
  }, [endDate]);

  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div className="page-content Pagebody">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Company Settings</h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="mb-3 row">
                      <label
                        htmlFor="phyicalYear"
                        className="col-md-2 col-form-label"
                      >
                        Fiscal Year
                      </label>
                      <div className="col-md-10">
                        <DatePicker
                          style={{ marginRight: "15px" }}
                          value={startDate}
                          onChange={onChange}
                          picker="month"
                        />
                        <DatePicker value={endDate} picker="month" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPhysicalPeriod;
