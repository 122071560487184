import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import DataParser from "../../../utlits/Dataparser";
import LeaveApplicationService from "../../../Services/LeaveApplicationService";
import LeaveSessionService from "../../../Services/LeaveSessionService";
import LeaveEntitlementService from "../../../Services/LeaveEntitlementService";
import CompanyService from "../../../Services/CompanyService";
import shortid from "shortid";

const AddLeavesHr = (props) => {
  const { id, labelledby, userLeaveCheck, getLeaveApplicationsAll } = props;

  const [leaveType, setLeaveType] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [numberOfDays, setNumberOfDays] = useState("");
  const [leavesDates, setLeavesDates] = useState([]);
  const [companyUserList, setCompanyUserList] = useState([]);

  const [userLeaves, setUserLeaves] = useState([]);
  const [userCurrentQuarterLeaveCount, setUserCurrentQuarterLeaveCount] =
    useState("");
  const [currentQuater, setCurrentQuater] = useState([]);
  const [currentQuaterName, setCurrentQuaterName] = useState("");

  const [leaveReason, setLeaveReason] = useState("");
  const [activeSession, setActiveSession] = useState(null);
  const [largeDate, setLargerDate] = useState("");
  const compid = DataParser.CompanyId();
  const [entitlement, setEntitlement] = useState([]);
  const [policayState, setPolicayState] = useState("");

  const gracePeriod = 15;
  const companyId = DataParser.CompanyId();
  const userId = DataParser.UserId();

  //definding quaters
  const Q1 = ["7", "8", "9"];
  const Q2 = ["10", "11", "12"];
  const Q3 = ["1", "2", "3"];
  const Q4 = ["4", "5", "6"];

  useEffect(() => {
    const datee = DataParser.getDateinFormat();
    getCompanyUsers();
    setFromDate(moment(datee));
    setToDate(datee);

    var currentQuater = [];
    const currentMonth = datee.split("-")[1];
    var leaveCount = 0;
    if (Q2.includes(currentMonth)) {
      currentQuater = Q2;
      setCurrentQuater(currentQuater);
      setCurrentQuaterName("Q2");
    }
    if (Q1.includes(currentMonth)) {
      currentQuater = Q1;
      setCurrentQuater(currentQuater);
      setCurrentQuaterName("Q1");
    }
    if (Q3.includes(currentMonth)) {
      currentQuater = Q3;
      setCurrentQuater(currentQuater);
      setCurrentQuaterName("Q3");
    }
    if (Q4.includes(currentMonth)) {
      currentQuater = Q4;
      setCurrentQuater(currentQuater);
      setCurrentQuaterName("Q4");
    }
    LeaveApplicationService.userLeave(userId, compid).then((res) => {
      const data = res.data.userLeaves;
      //const data = [];
      setUserLeaves(data);
      for (let i = 0; i < data.length; i++) {
        const dateMonth = data[i].date.split("/")[1];
        //console.log("dateMonth", dateMonth);
        if (currentQuater.includes(dateMonth)) {
          leaveCount++;
        }
      }
      //console.log("leaves counts check: ", leaveCount)
      setUserCurrentQuarterLeaveCount(leaveCount);
    });

    //checking currentquarer leave count
  }, []);

  const getCompanyUsers = () => {
    CompanyService.getComapnyUser(companyId).then((res) => {
      const data = res.data.user;
      setCompanyUserList(data);
    });
  };

  const userLeavess = () => {
    LeaveApplicationService.userLeave(userId, compid).then((res) => {
      const data = res.data.userLeaves;
      setUserLeaves(data);
    });
  };

  useEffect(() => {
    if (selectedUser !== "") {
      userLeaveEntitlementss();
    }
  }, [selectedUser]);

  useEffect(() => {
    // start time and end time
    var startTime = moment(fromDate, "YYYY, MM, DD");
    var endTime = moment(toDate, "YYYY, MM, DD");
    const days = endTime.diff(startTime, "days");
    enumerateDaysBetweenDates(startTime, endTime);

    getLeaveSession();
  }, [toDate]);

  const getLeaveSession = () => {
    LeaveSessionService.getLeaveSeassions(companyId).then((res) => {
      const data = res.data.Leave_sessions.filter((el) => el.status === 1);
      setActiveSession(data[0].id);
    });
  };

  const userLeaveEntitlementss = () => {
    LeaveEntitlementService.userLeaveEntitlement(selectedUser, companyId).then(
      (res) => {
        setEntitlement(res.data.entitlement);
      }
    );
  };

  const enumerateDaysBetweenDates = function (startDate, endDate) {
    var dates = [];

    var currDate = moment(startDate).startOf("day");
    var lastDate = moment(endDate).startOf("day");
    dates.push(moment(currDate).format("DD/MM/YYYY"));
    while (currDate.add(1, "days").diff(lastDate) < 0) {
      const data = currDate.clone().toDate();
      dates.push(moment(data).format("DD/MM/YYYY"));
    }
    if (
      moment(startDate).format("DD/MM/YYYY") !==
      moment(endDate).format("DD/MM/YYYY")
    ) {
      dates.push(moment(lastDate).format("DD/MM/YYYY"));
    }
    setLeavesDates(dates);
    setNumberOfDays(dates.length);
  };

  const onDateChangeFrom = (date) => {
    setFromDate(date);
  };
  const onDateChangeTo = (date) => {
    setToDate(date);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const link = document.getElementById("closeLeave");
    const body = {
      company_id: companyId,
      reason: leaveReason,
      empid: selectedUser,
      from_date: fromDate,
      to_date: toDate,
      totalleaves: numberOfDays,
      typ: leaveType,
      leavesession: activeSession,
      leavedates: leavesDates,
    };

    LeaveApplicationService.applyLeave(body).then((res) => {
      link.click();
      getLeaveApplicationsAll();
      userLeavess();
    });
  };

  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex={-1}
        id={id}
        aria-labelledby={labelledby}
        style={{ width: "600px" }}
      >
        <div className="offcanvas-header border-bottom">
          <h5 id={labelledby}>Leaves HR</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            id="closeLeave"
          />
        </div>
        <div className="offcanvas-body">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="card-content tasks_custom_styling add_task_input_fields mt-1 overxy">
            <div className="row" style={{paddingRight: '2rem'}}>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="basicSelect">
                        Employee Name<span className="alert_star">*</span>
                      </label>
                    </div>

                    <div className="col-sm-9">
                      <select
                        className="form-control selectset custominput"
                        style={{ border: "0px" }}
                        name="lead_owner"
                        id="basicSelect"
                        value={selectedUser}
                        onChange={(e) => setSelectedUser(e.target.value)}
                      >
                        <option value="">Please Select</option>
                        {companyUserList &&
                          companyUserList.map((el) => (
                            <option key={shortid.generate()} value={el.id}>{el.first_name}</option>
                          ))}
                      </select>
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label className="offcanvas_labels" htmlFor="basicSelect">
                        Leave Type<span className="alert_star">*</span>
                      </label>
                    </div>

                    <div className="col-sm-9">
                      <select
                        className="form-control selectset custominput"
                        style={{ border: "0px" }}
                        name="lead_owner"
                        id="basicSelect"
                        value={leaveType}
                        onChange={(e) => setLeaveType(e.target.value)}
                      >
                        <option value="">Please Select</option>
                        {entitlement &&
                          entitlement.map((el) => (
                            <option key={shortid.generate()} value={el.typeId}>{el.leaveType}</option>
                          ))}
                      </select>
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label
                        className="offcanvas_labels"
                        htmlFor="basicSelect1"
                      >
                        From<span className="alert_star">*</span>
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <DatePicker
                        id="basicSelect1"
                        disabledDate={(current) => {
                          let customDate = moment().format("YYYY-MM-DD");
                          return (
                            current &&
                            current < moment(customDate, "YYYY-MM-DD")
                          );
                        }}
                        allowClear={false}
                        bordered={false}
                        value={moment(fromDate)}
                        onChange={onDateChangeFrom}
                        format={"DD-MM-YYYY"}
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label
                        className="offcanvas_labels"
                        htmlFor="basicSelect2"
                      >
                        To<span className="alert_star">*</span>
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <DatePicker
                        id="basicSelect2"
                        allowClear={false}
                        bordered={false}
                        value={moment(toDate)}
                        onChange={onDateChangeTo}
                        format={"DD-MM-YYYY"}
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label
                        className="offcanvas_labels"
                        htmlFor="basicSelect3"
                      >
                        Number of days<span className="alert_star">*</span>
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <input
                        id="basicSelect3"
                        type="text"
                        className="form-control mb-3"
                        required
                        disabled
                        value={numberOfDays}
                        onChange={(e) => setNumberOfDays(e.target.value)}
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="col-12 col-sm-12">
                  <fieldset className="form-group row mt-2">
                    <div className="col-sm-3 pl-2">
                      <label
                        className="offcanvas_labels"
                        htmlFor="basicSelect4"
                      >
                        Leave Reason<span className="alert_star">*</span>
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <textarea
                        className="form-control w-100 autosize textset custominput2"
                        rows={3}
                        placeholder="Details"
                        id="basicSelect4"
                        name="details"
                        data-validation-required-message="This role field is required"
                        data-gramm="false"
                        wt-ignore-input="true"
                        value={leaveReason}
                        onChange={(e) => setLeaveReason(e.target.value)}
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="col-12">
                  <p className="offcanvas_labels text-danger">{policayState}</p>
                </div>
                <div className="col-12 d-flex flex-sm-row flex-column justify-content-end mt-4">
                  <button
                    type="submit"
                    // onClick={(e)  => handleSubmit(e)}
                    className="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddLeavesHr;
